// @ts-nocheck
import { useUcanStore } from '@/stores/ucan'
import { useNearWalletStore, type NearWalletStoreType } from '@/stores/near-wallet.js'
import { encryptValueUsingPublicKey } from '@/utils/rsa-keypair-encryption.js'
import { v4 as uuidv4 } from 'uuid'
import { isEqual } from 'lodash'
import { fetchProfile } from '@/queries/api'
import { CloudflareWorkersApi } from '@/queries/cloudflare-workers'
import { getPrivateKey, getPublicKey } from '@/utils/encryptChunks'
/**
 * A function that creates a profile record if it does not exist.
 *
 * This function checks if a profile record exists for a given account ID. If it does not, it creates one.
 * It uses the `getProfileDataByAccountId` function to check for the existence of a profile record.
 * If the record does not exist, it uses the `createOrUpdateAccount` function to create a new profile record.
 *
 * @param {string} accountId The account ID for which to create a profile record.
 * @param {string} fields A string specifying the fields of the profile data to return, defaults to "id".
 * @returns {Promise<void>} A promise that resolves when the profile record is created.
 *
 * @example
 * // Create a profile record for the current user.
 * createProfileRecordIfNonExistent('user123');
 *
 * // Create a profile record for the current user, and return only the "id" and "emailrsa" fields.
 * createProfileRecordIfNonExistent('user123', '
 *     id
 *     emailrsa
 * ');
 */
export const createProfileRecordIfNonExistent = async (accountId: string, fields = `id`) => {
  try {
    const profileRecord = await getProfileDataByAccountId(accountId, fields)

    if (!profileRecord || !profileRecord.id) {
      const createdProfile = await createOrUpdateAccount(accountId, profileRecord, {
        forceUpdateEmailRsa: false,
        forceUpdateDidKey: false,
        forceUpdateUcanToken: false
      })
      console.debug('createProfileRecordIfNonExistent: profile=', createdProfile)

      getProfileDataByAccountId.delete(accountId, fields)

      window.location.reload()
    }
  } catch (err) {
    console.debug('createProfileRecordIfNonExistent: error=', err)
  }
}

/**
 * Retrieves profile data by account ID from a GraphQL API.
 *
 * This function sends a POST request to the configured GraphQL endpoint to retrieve profile data
 * for a specific account ID. It allows specifying which keys (fields) of the profile data to fetch.
 * If the request is successful, it returns the requested data; otherwise, it throws an error.
 *
 * @param {string} accountId The account ID for which to fetch profile data.
 * @param {string} fields A string specifying the fields of the profile data to return, defaults to "id".
 * @returns {Promise<Object>} A promise that resolves with the profile data object for the given account ID.
 */
export const getProfileDataByAccountId = async (accountId, fields = `id`) => {
  // get the profile
  const profile = await fetchProfile(accountId)

  // filter out the fields we want
  if (profile) {
    const filtered = Object.keys(profile).reduce((acc, key) => {
      if (fields.includes(key)) {
        acc[key] = profile[key]
      }
      return acc
    }, {} as Partial<ProfileData>)

    // send back the filtered profile
    return filtered
  }
  return {}
}

/**
 * Asynchronously creates or updates an account profile based on the given account ID and profile information,
 * with optional forced updates on specific fields.
 *
 * This function first checks if the `accountId` is provided, throwing an error if it's missing.
 * It normalizes the force update flags for email RSA, DID key, and UCAN token based on the provided `forceUpdates` object.
 * It then retrieves the current stores and profile, updating the profile as necessary.
 *
 * The function may perform various updates including:
 * - Encrypting and updating the email RSA if not already set or if forced to update.
 * - Creating and updating the DID key if not already set or if forced to update.
 * - Issuing and updating UCAN tokens if not already present or if forced to update.
 *
 * Finally, it checks if any profile information has changed and updates the profile accordingly. The function
 * returns the updated profile if changes were made or the original profile if no changes were detected.
 *
 * @param {string} accountId - The account ID to create or update the profile for.
 * @param {object} profile - The current profile information.
 * @param {object} [forceUpdates={}] - Optional. An object specifying which fields to forcefully update, including:
 *                                      `forceUpdateEmailRsa`, `forceUpdateDidKey`, and `forceUpdateUcanToken`.
 * @returns {Promise<object>} A promise that resolves to the updated profile if changes were made, or the original profile if not.
 * @throws {Error} Throws an error if `accountId` is not provided.
 */
export const createOrUpdateAccount = async (
  accountId: string,
  profile: ProfileData,
  forceUpdates = {} as {
    forceUpdateEmailRsa?: boolean
    forceUpdateDidKey?: boolean
    forceUpdateUcanToken?: boolean
  }
) => {
  if (!accountId) {
    throw new Error('Account ID is required.')
  }

  // normalise
  const forceUpdateEmailRsa = forceUpdates.forceUpdateEmailRsa || false
  const forceUpdateDidKey = forceUpdates.forceUpdateDidKey || false
  const forceUpdateUcanToken = forceUpdates.forceUpdateUcanToken || false

  // get the stores
  const ucanStore = useUcanStore()
  const nearWalletStore = useNearWalletStore()

  // get the current profile
  const profileUpdates = { ...profile }

  // create the profile if we don't have one
  if (!profile.id) {
    profileUpdates.id = accountId
    profileUpdates.owner = accountId
    profileUpdates.emailrsa = undefined
    profileUpdates.didKey = undefined
    profileUpdates.ucanTokens = []
  }

  // store the email after encrypting if we don't have the emailrsa already
  if ((nearWalletStore?.user?.email && !profileUpdates.emailrsa) || forceUpdateEmailRsa) {
    const emailrsa = await encryptValueUsingPublicKey(
      ((nearWalletStore.user || {}).email || '').trim()
    )
    if (emailrsa) {
      profileUpdates.emailrsa = emailrsa
      console.debug('createOrUpdateAccount: email changed')
    }
  }

  // create the didKey for storage if we don't have a didKey
  if ((ucanStore.data?.secp256k1PrivateKey && !profileUpdates.didKey) || forceUpdateDidKey) {
    const didKey = await ucanStore.edDSAKeyPairDid()
    if (didKey) {
      profileUpdates.didKey = didKey
      console.debug('createOrUpdateAccount: didKey changed')
    }
  }

  // CMG... aud and accountId
  const cmgDidKey = ucanStore.ucanNotificationsAudience?.didKey
  const cmgAccountId = ucanStore.ucanNotificationsAudience?.nearNamedWalletId

  if (profileUpdates.didKey && cmgDidKey && cmgAccountId) {
    const hasUcanToken = profileUpdates?.ucanTokens?.some(
      (ucanToken) =>
        ucanToken.iss === profileUpdates.didKey &&
        ucanToken.aud === cmgDidKey &&
        ucanStore.checkUcanTokenIssuerAccountName(ucanToken.token, accountId)
    )

    if (!hasUcanToken || forceUpdateUcanToken) {
      const ucanToken = await ucanStore.issueNotificationsUcan({
        audienceDid: cmgDidKey,
        audienceWalletId: cmgAccountId,
        issuerEmailrsa: profileUpdates.emailrsa
      })

      if (ucanToken) {
        profileUpdates.ucanTokens = Array.isArray(profileUpdates.ucanTokens)
          ? profileUpdates.ucanTokens
          : []

        profileUpdates.ucanTokens = profileUpdates.ucanTokens.filter(
          (ucanToken) => ucanToken.iss === profileUpdates.didKey && ucanToken.aud === cmgDidKey
        )

        profileUpdates.ucanTokens.push({
          id: uuidv4(),
          iss: profileUpdates.didKey,
          aud: cmgDidKey,
          token: ucanToken
        })

        console.debug('createOrUpdateAccount: ucanToken changed')
      }
    }
  }

  // update the profile if something has changed
  const profileChanged = !isEqual(profile, profileUpdates)
  if (profileChanged) {
    profileUpdates.owner = accountId
    await submitProfileSave(nearWalletStore, accountId, profileUpdates)
    console.debug('createOrUpdateAccount: updated=', profileUpdates)
  }

  // send back the changed profile or the one that was passed in
  return profileChanged ? profileUpdates : profile
}

export const submitProfileSave = async (
  nearWalletStore: NearWalletStoreType,
  accountId: string,
  data: Partial<ProfileData>
) => {
  // remove un-needed fields
  delete data.uuid
  delete data.createdAt
  delete data.updatedAt

  // ensure we have an accountid
  data.owner = accountId

  // Loop through the social media fields and check if they contain a valid URL or the default value
  const defaultValues = {
    facebook: 'https://www.facebook.com/',
    instagram: 'https://www.instagram.com/',
    twitter: 'https://twitter.com/',
    website: 'https://',
    behance: 'https://www.behance.net/',
    tiktok: 'https://www.tiktok.com/@',
    pexels: 'https://www.pexels.com/member/',
    linkedin: 'https://www.linkedin.com/in/',
    youtube: 'https://www.youtube.com/@'
  }

  function updateSocialMediaLinks(target) {
    Object.entries(defaultValues).forEach(([field, defaultValue]) => {
      if (typeof target[field] !== 'undefined') {
        const fieldValue = target[field]
        if (fieldValue === '__NULL__') {
          target[field] = null
        } else if (fieldValue && !fieldValue.startsWith('http')) {
          target[field] = defaultValue + fieldValue
        } else if (!fieldValue || fieldValue === defaultValue) {
          target[field] = null
        }
      }
    })
    return target
  }

  data = updateSocialMediaLinks(data)
  data.socials = updateSocialMediaLinks(data.socials || {})

  // check contents is array if not then add a default blank array
  data.contents = data?.contents && Array.isArray(data.contents) ? data.contents : []

  // add 'id' and 'owner' to fields if we don't have it
  if (typeof data.fields === 'object' && data.fields) {
    if (!data.fields.id && accountId) {
      data.fields.id = accountId
    }

    if (!data.fields.owner && accountId) {
      data.fields.owner = accountId
    }
  }

  // add to IPFS
  // const { cid } = await pinDataToIPFS(JSON.stringify(data))
  // console.debug(`Profile data saved to IPFS with CID: ${cid.toString()}`)

  // store in the Database

  /*
  if (calculateObjectSizeInBytes(data) > 128 * 1024) {
    const err = 'Profile data is too large to store on the blockchain.'
    alert(err)
    throw new Error('Profile data is too large to store on the blockchain.')
    return
  }
  */

  // get the PublicKey
  const ucanStore = useUcanStore()
  const privateKey = await getPrivateKey(ucanStore.data?.secp256k1PrivateKey)
  const publicKey = getPublicKey(privateKey).toString('hex')

  // 400 error? contents 'not an array string passed' error? can be things inside the array like tags being a string, not an array

  // loop through data.contents and convert data.contents.tags to array if its a string
  for (let i = 0; i < data.contents.length; i++) {
    if (!data.contents[i].tags) {
      data.contents[i].tags = []
    }
    if (typeof data.contents[i].tags === 'string' && data.contents[i].tags.includes(',')) {
      data.contents[i].tags = data.contents[i].tags.split(',')
    }
    // missing description
    if (!data.contents[i].short_description && data.contents[i].description) {
      data.contents[i].short_description = data.contents[i].description
    }
  }

  await CloudflareWorkersApi().post('/v1/profiles', {
    id: accountId,
    owner: accountId,
    fields: {
      ...data,
      cid: '',
      publicKey
    }
  })

  // store on the Blockchain
  // return await nearWalletStore.updateProfile(cid.toString())
}

export const nextPageForProfile = (accountId) => {
  const loc = window.location
  const urlPrefix = `${loc.protocol}//${loc.host}`.replace(/\/$/, '')
  let nextPageUrl = `${urlPrefix}/user/profile/${accountId}`

  const fromValue = new URLSearchParams(loc.search).get('from') || ''
  if (fromValue.startsWith('/briefs/briefdetails/')) {
    nextPageUrl = urlPrefix + fromValue
  }

  window.location.href = nextPageUrl
}

export const deleteProfileContent = async (nearWalletStore, ids: any[]) => {
  updateProfileContent(nearWalletStore, ids, 'delete')
}

export const updateProfileContent = async (nearWalletStore, saveData: any, mode = 'update') => {
  if (typeof saveData !== 'object') {
    saveData = JSON.parse(saveData)
  }

  // accountId
  const accountId = nearWalletStore?.accountId

  if (!accountId) {
    console.error('updateProfileContent: accountId is required')
    return
  }

  console.log('updateProfileContent: mode', mode)

  // fetch the profile
  const profile = await fetchProfile(accountId)

  // ensure we have a profile
  if (Object.keys({ ...profile }).length <= 0) {
    console.error('updateProfileContent: no profile data')
    return false
  }

  let updatedContents = profile?.contents?.length ? [...profile.contents] : []

  // append / update
  if (mode === 'update') {
    // append new content
    saveData.forEach((item) => {
      if (!item.id) {
        item.id = uuidv4()
      }
      const updatedContentsUploadIndex = updatedContents.findIndex((val) => val.id === item.id)
      if (updatedContentsUploadIndex == -1) {
        // console.log('updateProfileContent: appending item', item)
        updatedContents.push(item)
      } else {
        // console.log('updateProfileContent: updating item', item)
        updatedContents[updatedContentsUploadIndex] = item
      }
    })
  }

  // delete - remove any matching ids, remove the dummy deleted records
  if (mode === 'delete') {
    console.log('updateProfileContent: deleting items')
    updatedContents = updatedContents.filter((item) => !saveData.includes(item.id))
  }

  // remove any empty records
  updatedContents = updatedContents.filter((item) => item.file_url)

  // create the updated profile to save
  profile.contents = updatedContents

  // save
  // console.log('updateProfileContent: saving contents', profile.contents)
  await submitProfileSave(nearWalletStore, accountId, profile)
  // redirect user back if we succeeded and we have a return link
  nextPageForProfile(accountId)
}
