import { formatSmartBriefData } from '@/utils/common'
import { CloudflareWorkersApi } from '@/queries/cloudflare-workers'
import FormData from 'form-data'

export type FetchSmartBriefsParams = {
  option: string & ('live' | 'archived')
}

export const getSmartBriefs = async (
  params: FetchSmartBriefsParams = {
    option: 'live'
  }
) => {
  try {
    const resp = await CloudflareWorkersApi().get(`/v1/smartbriefs`, {
      params
    })
    return resp?.data?.briefs || []
  } catch (error) {
    return []
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export const getMySmartBriefs = async (
  type: 'applied' | 'created' = 'created',
  option: string = 'live'
) => {
  try {
    const resp = await CloudflareWorkersApi().get(`/v1/smartbriefs/my/${type}?option=${option}`)
    return resp?.data?.briefs || []
  } catch (error) {
    return []
  }
}

export const checkBriefSlug = async (slug: string, briefId?: string): Promise<boolean> => {
  try {
    const briefIdSegment = briefId ? `/${briefId}` : ''
    const resp = await CloudflareWorkersApi().get('/v1/smartbriefs/slug/' + slug + briefIdSegment)
    return Boolean(resp?.data?.valid) || false
  } catch (error) {
    return false
  }
}

export const getSmartBrief = async (
  id: string,
  accountId: string
): Promise<SmartBrief | undefined> => {
  try {
    // const resp = await CloudflareWorkersApi().get(`/v1/smartbriefs/${id}`)
    const resp = await fetchBrief(id)
    if (resp?.status === 'published' || resp?.owner === accountId) {
      return resp || {}
    }
    return
  } catch (error) {
    console.error('getSmartBrief error:', error)
    return
  }
}

export const fetchBrief = async (id: string): Promise<SmartBrief> => {
  return (await CloudflareWorkersApi().get(`/v1/smartbriefs/${id}`)).data.brief || {}
}

export const saveSmartBrief = async (ucanStore: any, data: any) => {
  const newData = formatSmartBriefData(data)
  const ucanToken = await ucanStore.getSelfSignedUCAN(data.owner)
  const headers = {
    headers: {
      Authorization: 'Bearer ' + ucanToken
    }
  }
  const resp = await CloudflareWorkersApi().post('/v1/smartbriefs', newData, headers)
  // console.debug('saveSmartBrief: /v1/smartbriefs', resp.data)
  return resp.data
}

export const saveSmartBriefPaymentPreference = async (
  ucanStore: any,
  briefId: string,
  data: any
) => {
  const apiData: any = {}
  apiData['briefId'] = briefId
  apiData['action'] = 'payment_preference'
  apiData['paymentPreference'] = data
  const respPayment = await CloudflareWorkersApi().post('/v1/smartbriefs/action', apiData)
  console.debug('onSubmit: /v1/smartbriefs/action', respPayment)
}

export const publishSmartBrief = async (ucanStore: any, briefId: string) => {
  const apiData: any = {}
  apiData['briefId'] = briefId
  apiData['action'] = 'publish_brief'
  const resp = await CloudflareWorkersApi().post('/v1/smartbriefs/action', apiData)
  console.debug('onSubmit: /v1/smartbriefs/action', resp)
}

export const unpublishSmartBrief = async (ucanStore: any, briefId: string) => {
  const apiData: any = {}
  apiData['briefId'] = briefId
  apiData['action'] = 'unpublish_brief'
  const resp = await CloudflareWorkersApi().post('/v1/smartbriefs/action', apiData)
  console.debug('onSubmit: /v1/smartbriefs/action', resp)
}

export const submitSmartBriefNDA = async (ucanStore: any, briefId: string) => {
  const apiData: any = {}
  apiData['briefId'] = briefId
  const resp = await CloudflareWorkersApi().post('/v1/smartbriefprofiles/submit_nda', apiData)

  if (typeof window?.gtag === 'function') {
    window.gtag('event', 'brief_creator_submit_nda', {
      event_category: 'brief',
      brief_id: briefId
    })
  }

  window?.posthog?.capture('brief_creator_submit_nda', {
    category: 'brief',
    brief_id: briefId
  })

  console.debug('onSubmit: /v1/smartbriefprofiles/submit_nda', resp)
}

export const acceptSmartBriefNDA = async (
  ucanStore: any,
  briefId: string,
  message: string,
  ucanTokens?: UcanToken[]
) => {
  const apiData: any = {}
  apiData['briefId'] = briefId
  apiData['message'] = message
  if (ucanTokens && ucanTokens.length) {
    apiData['ucanTokens'] = ucanTokens
  }
  const resp = await CloudflareWorkersApi().post('/v1/smartbriefprofiles/accept_nda', apiData)

  if (typeof window?.gtag === 'function') {
    window.gtag('event', 'brief_creator_accept_nda', {
      event_category: 'brief',
      brief_id: briefId
    })
  }

  window?.posthog?.capture('brief_creator_accept_nda', {
    category: 'brief',
    brief_id: briefId
  })

  console.debug('onSubmit: /v1/smartbriefprofiles/accept_nda', resp)
}

export const getSmartBriefProfiles = async (ucanStore: any, briefId: string) => {
  try {
    const resp = await CloudflareWorkersApi().get(`/v1/smartbriefprofiles/${briefId}`)
    return (resp?.data?.briefProfiles || []) as SmartBriefProfile[]
  } catch (error) {
    return []
  }
}

export const updateSmartBriefProfileStatus = async (ucanStore: any, data: any) => {
  switch (data.status) {
    case 'approved':
      if (data['feedback']) {
        data['action'] = 'submit_feedback'
      } else {
        data['action'] = 'approve_creator'
      }
      break
    case 'shortlisted':
      data['action'] = 'shortlist_creator'
      break
    case 'rejected':
      data['action'] = 'reject_creator'
      break
    case 'accepted':
      data['action'] = 'accept_creator'
      break
  }
  if (data['action']) {
    const resp = await CloudflareWorkersApi().post('/v1/smartbriefprofiles/action', data)

    const ga_event = {
      submit_feedback: 'feedback',
      approve_creator: 'approve',
      shortlist_creator: 'shortlist',
      reject_creator: 'reject',
      accept_creator: 'accept,'
    }[String(data['action'])]

    if (ga_event) {
      if (typeof window?.gtag === 'function') {
        window.gtag('event', `brief_creator_${ga_event}`, {
          event_category: 'brief',
          brief_id: data?.briefId || 0
        })
      }

      window?.posthog?.capture(`brief_creator_${ga_event}`, {
        category: 'brief',
        brief_id: data?.briefId || 0
      })
    }

    console.debug('onSubmit: /v1/smartbriefprofiles/action', resp)
  }
}

export const addSmartBriefContent = async (ucanStore: any, data: any) => {
  const resp = await CloudflareWorkersApi().post('/v1/smartbriefcontents', data)
  console.debug('onSubmit: /v1/smartbriefcontents', resp)
}

export const getProfileCompletenessPercentages = async (
  id: string | string[]
): Promise<Record<string, number>> => {
  const resp = await CloudflareWorkersApi().post(`/v1/profiles/completeness-percentages`, {
    id: Array.isArray(id) ? id : [id]
  })
  return resp?.data
}

export const fetchProfile = async (accountId: string): Promise<ProfileData> =>
  (await CloudflareWorkersApi().get(`/v1/profiles/${accountId}`)).data || {}

export const addSmartBriefContentFeedback = async (data: any) => {
  return (
    await CloudflareWorkersApi().post('/v1/smartbriefprofiles/feedback', data)
  )?.data?.briefFeedback?.pop()
}

export const requestSmartBriefPayment = async (
  ucanStore: any,
  briefId: string,
  amountRequested: string
) => {
  const apiData: any = {}
  apiData['briefId'] = briefId
  apiData['amountRequested'] = amountRequested
  const resp = await CloudflareWorkersApi().post('/v1/smartbriefprofiles/request_payment', apiData)
  console.debug('onSubmit: /v1/smartbriefprofiles/request_payment', resp)
}

export const downloadSmartBriefFiles = async (briefId: string) => {
  const resp = await CloudflareWorkersApi().get(`/v1/smartbriefs/${briefId}/download`, {
    responseType: 'blob'
  })
  return resp
}

/*
 * Team Related
 */

// Check if a team nickname is available
export const checkTeamNicknameIsAvailable = async (nickname: string, teamUuid?: string) => {
  const resp = await CloudflareWorkersApi().post(`/v1/teams/nickname/check`, {
    nickname,
    teamUuid
  })
  return resp.data
}

// Create a new team
export const createTeam = async (data: Partial<Team>): Promise<Team> => {
  return (await CloudflareWorkersApi().post(`/v1/teams`, data))?.data
}

// Get all teams
export const getTeams = async (): Promise<Team[]> => {
  return (await CloudflareWorkersApi().get(`/v1/teams`))?.data
}

// Get a team by key and value
export const getTeam = async (teamKey: 'uuid' | 'nickname', teamValue: string): Promise<Team> => {
  return (await CloudflareWorkersApi().get(`/v1/teams/${teamKey}/${teamValue}`))?.data
}

// Get the Briefs attached to this Team
export const getTeamBriefs = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  params: FetchSmartBriefsParams = {
    option: 'live'
  }
): Promise<SmartBrief[]> => {
  return (
    await CloudflareWorkersApi().get(`/v1/teams/${teamKey}/${teamValue}/briefs`, {
      params
    })
  )?.data
}

// Update a team by key and value
export const updateTeam = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  data: Partial<Team>
): Promise<Team> => {
  return (await CloudflareWorkersApi().put(`/v1/teams/${teamKey}/${teamValue}`, data))?.data
}

// Get all members of a team
export const getTeamMembers = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string
): Promise<TeamMemberWithRoleAndProfile[]> => {
  return (await CloudflareWorkersApi().get(`/v1/teams/${teamKey}/${teamValue}/members`))?.data
}

// Delete a member from a team
export const deleteTeamMember = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  memberId: string
): Promise<void> => {
  return (
    await CloudflareWorkersApi().delete(`/v1/teams/${teamKey}/${teamValue}/members/${memberId}`)
  )?.data
}

// Get the user's permissions for this team
export const getTeamMemberPermissions = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  memberId: string
): Promise<string[]> => {
  return (
    await CloudflareWorkersApi().get(
      `/v1/teams/${teamKey}/${teamValue}/members/${memberId}/permissions`
    )
  )?.data
}

// Get all invites for a team
export const getTeamInvites = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string
): Promise<TeamInvite[]> => {
  return (await CloudflareWorkersApi().get(`/v1/teams/${teamKey}/${teamValue}/invites`))?.data
}

// Create a new invite for a team
export const createTeamInvite = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  data: Pick<TeamInvite, 'roleUuid' | 'email' | 'message' | 'token'> & {
    inviteLink: string
    teamLink: string
  }
): Promise<TeamInvite> => {
  return (await CloudflareWorkersApi().post(`/v1/teams/${teamKey}/${teamValue}/invites`, data))
    ?.data
}

// Delete an invite for a team
export const deleteTeamInvite = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  inviteId: string
): Promise<void> => {
  return (
    await CloudflareWorkersApi().delete(`/v1/teams/${teamKey}/${teamValue}/invites/${inviteId}`)
  )?.data
}

// Get an invite for a team
export const getTeamInvite = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  inviteId: string,
  inviteToken?: string
): Promise<TeamInvite> => {
  return (
    await CloudflareWorkersApi().get(`/v1/teams/${teamKey}/${teamValue}/invites/${inviteId}`, {
      params: {
        inviteToken
      }
    })
  )?.data
}

// Accept or reject an invite for a team
export const handleTeamInvite = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  inviteId: string,
  action: 'accept' | 'reject',
  inviteToken: string
): Promise<void> => {
  return (
    await CloudflareWorkersApi().post(
      `/v1/teams/${teamKey}/${teamValue}/invites/${inviteId}/${action}?inviteToken=${inviteToken}`
    )
  )?.data
}

// Get all roles for a team
export const getTeamRoles = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  query: {
    includePermissions?: boolean
  } = {}
): Promise<TeamRoleWithPermissions[]> => {
  return (
    await CloudflareWorkersApi().get(`/v1/teams/${teamKey}/${teamValue}/roles`, {
      params: query
    })
  )?.data
}

// Get Team Roles
export const createTeamRole = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  data: Pick<TeamRoleWithPermissions, 'name' | 'displayName' | 'permissions'>
): Promise<TeamRoleWithPermissions> => {
  return (await CloudflareWorkersApi().post(`/v1/teams/${teamKey}/${teamValue}/roles`, data))?.data
}

// Update a Team Role
export const updateTeamRole = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  roleId: string,
  data: Pick<TeamRoleWithPermissions, 'name' | 'displayName' | 'permissions'>
): Promise<TeamRoleWithPermissions> => {
  return (
    await CloudflareWorkersApi().put(`/v1/teams/${teamKey}/${teamValue}/roles/${roleId}`, data)
  )?.data
}

// Delete a Team Role
export const deleteTeamRole = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string,
  roleId: string
): Promise<void> => {
  return (await CloudflareWorkersApi().delete(`/v1/teams/${teamKey}/${teamValue}/roles/${roleId}`))
    ?.data
}

// Get all permissions available for a team
export const getAvailablePermissions = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string
): Promise<TeamPermission[]> => {
  return (await CloudflareWorkersApi().get(`/v1/teams/${teamKey}/${teamValue}/permissions`))?.data
}

// Get the Permissions for a briefId for the loggedInUser
export const getMyPermissionsForBriefId = async (briefId: string): Promise<string[]> => {
  return (
    (await CloudflareWorkersApi().get(`/v1/smartbriefs/permissions/${briefId}`))?.data
      ?.permissions || []
  )
}

// Get Team Messages
export const getTeamMessages = async (
  teamKey: 'uuid' | 'nickname',
  teamValue: string
): Promise<SmartBriefFeedback[]> => {
  return (await CloudflareWorkersApi().get(`/v1/teams/${teamKey}/${teamValue}/messages`))?.data
}

export const pinFileToIpfs = async (data: File, fileName?: string) => {
  const formData = new FormData()
  formData.append('file', data)

  const resp = await CloudflareWorkersApi().post('/ipfs/pin', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  console.debug('onSubmit: /v1/ipfs/pin', resp)
  return resp
}
