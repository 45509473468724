<script lang="ts" setup>
import Markdown from '@/components/Markdown.vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  body: {
    type: String,
    default: ''
  },
  bgGradient: {
    type: Boolean,
    default: false
  },
  imageClasses: {
    type: Array,
    default: []
  },
  imageSource: {
    type: String,
    default: ''
  },
  imagePosition: {
    type: String,
    default: 'left'
  },
  class: {
    type: String,
    default: ''
  }
})
</script>

<template>
  <div :class="props.class">
    <div class="roboto font-medium">
      <div
        class="flex"
        :class="{
          'flex-row-reverse': props.imagePosition === 'left'
        }"
      >
        <div class="w-full md:w-1/2 self-start">
          <h3 class="roboto text-xl font-semibold tracking-wide mb-3" style="text-align: left">
            {{ props.title }}
          </h3>
          <div class="text-sm lg:text-md">
            <Markdown :content="props.body" />
          </div>
        </div>
        <div class="hidden md:block w-full md:w-1/2 self-end" v-if="!!props.imageSource">
          <img loading="lazy" :src="props.imageSource" :class="props.imageClasses" />
        </div>
      </div>
    </div>
  </div>
</template>
