<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { computed, onUnmounted, reactive, ref, watch, watchEffect, onMounted } from 'vue'
import { isWebpSupported } from '@/utils/webp'
import { mobileDetect } from '@/utils/browser'
import { useHead } from '@unhead/vue'
import { useRoute, useRouter } from 'vue-router'
import { baseUrl } from '@/utils/route'
import { getFileType } from '@/utils/mediaFileTypes'
import {
  getProfileCompletenessPercentages,
  getSmartBriefProfiles,
  publishSmartBrief,
  submitSmartBriefNDA,
  acceptSmartBriefNDA,
  updateSmartBriefProfileStatus,
  addSmartBriefContent,
  fetchProfile
} from '@/queries/api'

import _ from 'lodash'

import { useActorRef } from '@xstate/vue'
import { briefMachineNDAStatus, type BriefMachineEvent } from '@/utils/xstate'

import { useTeamStore } from '@/stores/team'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import { v4 as uuidv4 } from 'uuid'

import { Vue3Lottie } from 'vue3-lottie'
import { convertDBDate } from '@/utils/dateChecks'
import { isImage, isVideo, getFileNameAndExtension } from '@/utils/mediaFileTypes'

import { RouterLink } from 'vue-router'
import { useUcanStore } from '@/stores/ucan'
import { extractFileUrl } from '@/utils/mediaDecrypt'
import { useBriefStore } from '@/stores/briefs'

import busy from '@/lottie/busy.json' with { type: 'json' }
import Alert from '@/components/Alert.vue'
import AlertDialog from '@/components/AlertDialog.vue'
import BriefsLayout from '@/Layouts/BriefsLayout.vue'
import BriefDetailsActionPanel from '@briefs/BriefDetailsActionPanel.vue'
import BriefDetailsCandidates from '@briefs/BriefDetailsCandidates.vue'
import BriefDetailsSubmissions from '@briefs/BriefDetailsSubmissions.vue'
import BriefDetailsLicencedContent from '@briefs/BriefDetailsLicencedContent.vue'
import BriefDetailsMySubmissions from '@briefs/BriefDetailsMySubmissions.vue'
import BriefDetailsMyLicencedContent from '@briefs/BriefDetailsMyLicencedContent.vue'
import BriefDetailsPageFullWidth from '@briefs/BriefDetailsPageFullWidth.vue'
import BriefDetailsPageSplitWithImage from '@briefs/BriefDetailsPageSplitWithImage.vue'
import BriefDetailsPageSplitWithCarousel from '@briefs/BriefDetailsPageSplitWithCarousel.vue'
import BriefDetailsHeader from '@briefs/BriefDetailsHeader.vue'
import BriefDetailsQA from '@briefs/BriefDetailsQA.vue'
import Markdown from '@/components/Markdown.vue'
import MediaItem from '@media/MediaItem.vue'
import MediaCarousel from '@media/MediaCarousel.vue'
import Modal from '@/components/Jetstream/Modal.vue'
import CreativesWizard from '@/components/Pages/Creatives/Wizard/Wizard.vue'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import Wrapper from '@/components/Pages/Creatives/Wizard/Shared/Wrapper.vue'
import CreativesWizardStart from '@/components/Pages/Creatives/Wizard/Start.vue'
import { sortByDateProperty } from '@/utils/sort'

const { t: $t } = useI18n({})

const isMobile = ref(mobileDetect())
const updatePageWidth = () => {
  isMobile.value = mobileDetect()
}
onMounted(() => {
  updatePageWidth()
  window.addEventListener('resize', updatePageWidth)
})
onUnmounted(() => {
  window.removeEventListener('resize', updatePageWidth)
})

useHead({
  title: 'View brief | Contented'
})

export interface Props {
  brief_id: string
  active_tab?: string
}

const props = withDefaults(defineProps<Props>(), {
  brief_id: '',
  active_tab: 'details'
})

const $route = useRoute()
const $router = useRouter()

const uppyProgress = ref(0)
const uppyBusy = ref(false)
const uploadMessage = ref('Uploading')

// const toast = useToast()

const teamStore = useTeamStore()

const nearWalletStore = useNearWalletStore()

const briefData = ref<SmartBrief>()
const briefDataFailedToLoad = ref<boolean>(false)

const ucanStore = useUcanStore()
const briefsStore = useBriefStore()

const { walletReady, accountId } = storeToRefs(nearWalletStore)
const { briefActive } = storeToRefs(briefsStore)

const briefMachineActor = useActorRef(briefMachineNDAStatus)
/* eslint-disable @typescript-eslint/no-unused-vars */
briefMachineActor.subscribe((snapshot) => {
  // console.debug('briefMachineActor.subscribe', snapshot)
})
briefMachineActor.start()

// display state vars
const showDebug = ref(false)

// wrapper so we can debug with a log
const sendState = (stateChange: BriefMachineEvent) => {
  if (showDebug.value || showPreview.value) {
    console.debug('[' + stateChange + ']')
  }
  if (showPreview.value) {
    console.log('No real state changes in preview mode')
    return
  }

  briefMachineActor.send(stateChange)
}

const params = reactive({
  id: '',
  accountId: ''
})

const profileData = ref<ProfileData>()

const refetchBrief = async () => {
  isLoading.value = true

  let briefId = params.id

  // copy what we have from the cache
  briefData.value = _.cloneDeep(briefsStore.switchActiveBrief(briefId))

  // fetch the brief from the server
  briefData.value = await briefsStore.fetchBrief(briefId, accountId.value)
  if (briefData.value) {
    // update params.id to the uuid as it might be the slug
    if (params.id !== briefData.value.id) {
      params.id = briefData.value.id
      briefId = params.id
    }

    // fetch the team related data
    const hasTeamChanged = teamStore.team?.uuid !== briefData.value?.teamUuid

    // reset the state if the team has changed
    if (hasTeamChanged) {
      teamStore.resetState()
    }

    // get the permissions for this brief
    if (nearWalletStore.accountId && briefId) {
      teamStore.fetchBriefPermissions(briefId).then(() => {
        // if the team has changed, fetch the team
        if (briefData.value?.teamUuid && hasTeamChanged) {
          teamStore.fetchTeam('uuid', briefData.value.teamUuid)
        }
      })
    }

    // get the candidates for this brief
    if (nearWalletStore.accountId && briefId) {
      getSmartBriefProfiles(ucanStore, briefId).then(async (resultsSBP: SmartBriefProfile[]) => {
        if (briefData.value) {
          // fetch the completeness percentages for each candidate's profile
          const profileCompletenessPercentages = await getProfileCompletenessPercentages(
            resultsSBP?.map((profile) => profile.userId)
          )

          // we need to do this because we use account_id in a lot of places...
          // THIS IS WHY I KEEP SAYING STOP CHANGING THE DATA STRUCTURE WITHOUT UNDERSTANDING THE CONSEQUENCES FFS
          const candidateProfiles = resultsSBP?.map((prf) => ({
            ...prf,
            account_id: prf.userId,
            profileCompletenessPercentage: profileCompletenessPercentages[prf.userId] || 0
          }))

          const acceptedProfiles = candidateProfiles
            .filter((profile) => profile.contentStatus === 'accepted')
            .map((profile) => profile.userId)

          briefData.value = {
            ...briefData.value,
            candidateProfiles,
            acceptedProfiles
          }
        }
      })
    }

    // Get NDA status from localStorage, in case user has submitted NDA when non-loggedin
    const storedNDAStatus = localStorage.getItem('ndaStatus')
    const ndaStatus = storedNDAStatus ? JSON.parse(storedNDAStatus) : {}
    if (ndaStatus[briefData.value.id]) {
      briefData.value.ndaSubmitted.push('non-logged-in-user')
    }

    // clone brief
    brief.value = _.cloneDeep(briefData.value)
  }

  briefDataFailedToLoad.value = briefData.value?.id ? false : true

  isLoading.value = false
}

const refetchProfile = async () => {
  profileData.value = await fetchProfile(accountId.value)
}

onMounted(async () => {
  await refetchBrief()
})

watch(accountId, async (newAccountId) => {
  if (newAccountId) {
    console.log('newAccountId: ', newAccountId)
    await refetchProfile()
    await refetchBrief()
  }
})

// const { result: briefData, refetch: refetchBrief } = useQuery(getSmartBrief(), params)
// const { result: profileData } = useQuery(getProfileData(), params)

const refreshBrief = async (callerName = '') => {
  isLoading.value = true
  // subgraph takes a sec to update
  setTimeout(async () => {
    console.info('BriefDetails : refreshBrief!')

    await refetchBrief()
    // this doesn't notice deep changes so we call it manually to make sure states are correct
    myWatch()

    if (callerName === 'submitContent') {
      setTimeout(() => {
        const btnElement: HTMLButtonElement | null = document.querySelector(
          '.candidate-upload .click-upload-delete.hidden'
        )
        btnElement?.click()
        // toast.success('Uploaded')
      }, 100)
    }

    isLoading.value = false
  }, 3000)
}

// auto refresh
const refreshCount = ref(0),
  lastLength = ref(0)

clearInterval(window.refreshBriefInterval)
window.refreshBriefInterval = setTimeout(() => {
  refreshCount.value++
  if (refreshCount.value > 3) {
    console.log('Refetch', refreshCount.value)
  }
  refetchBrief()
  // watchEffect doesn't notice deep changes so we call it manually to make sure states are correct
  if (briefData.value) {
    brief.value = {
      ...briefData.value
    }
    if (brief.value?.contents?.length && brief.value.contents.length != lastLength.value) {
      lastLength.value = brief.value.contents.length
      myWatch()
    }
  }
}, 1000)

onUnmounted(() => {
  clearInterval(window.refreshBriefInterval)
})

const brief = ref<SmartBrief>()
const metaData = ref<any[] | undefined>([])
const contentRights = ref<any | undefined>([])
const deliverables = ref<any | undefined>([])
const briefImage = ref('')

const UploadRefLogo = ref()
const currentTab = ref(props.active_tab || 'details')
const confirmDialog = ref<InstanceType<typeof AlertDialog> | null>(null)

const pathname = ref(window.location.pathname)

const isAccepted = ref(false)
const isBrandView = ref(true)
const isCreatorView = ref(!isBrandView.value)
const isNDAChecked = ref(false)
const isNDAAccepted = ref(false)
const isNDASubmitted = ref(false)
const isProfileFilled = ref(!!profileData.value)
const isPaymentPreferenceFilled = ref(brief.value?.paymentPreference != null)
const isPublished = ref(true)

// debug in preview. for design so we can see all states
const previewClass =
  'cursor-pointer text-xs p-1 mx-1 rounded-md bg-gray-200 transition-all duration-1000'
const isPreview = ref(document.location.host.match('preview.contentedmedia'))

// show preview bar
const showPreview = ref(false)

// you need to hide after setting on mobile or it messes up inspection tools
const hidePreviewBar = ref(true)

// local state that we can override in preview mode
const previewState = ref()

const isLoading = ref(false)
const pageReady = ref(false)
const ndaTextModal = ref(false)

const currentStep = ref(1)
const handleStepChanged = (step: number) => {
  currentStep.value = step
}

const emit = defineEmits(['stepChanged'])

const changeStep = (step: number): void => {
  currentStep.value = step
  emit('stepChanged', step)
}

const pageWidth = () => {
  return document.documentElement.clientWidth
}

const hidePreview = () => {
  previewState.value = ''
  showPreview.value = false
}

const submitNDA = async () => {
  if (!brief.value) {
    console.debug('submitNDA : no brief')
    return
  }

  if (showPreview.value) {
    // no changes when preview tab is showing.
    console.log('submitNDA : action disabled when preview is active')
    return
  }
  if (isNDAChecked.value) {
    isLoading.value = true

    if (accountId.value) {
      // calling api to submit NDA to db
      await submitSmartBriefNDA(ucanStore, brief.value.id)
    } else {
      // For non-logged-in user, store NDA status in localStorage
      const storedNDAStatus = localStorage.getItem('ndaStatus')
      const ndaStatus = storedNDAStatus ? JSON.parse(storedNDAStatus) : {}
      ndaStatus[brief.value.id] = true
      localStorage.setItem('ndaStatus', JSON.stringify(ndaStatus))
    }

    sendState({ type: 'SET_NDA_STATUS', nda_status: true })
    await refreshBrief()
  } else {
    alert('You must check the box to accept the NDA')
  }
}

const acceptNDA = async () => {
  if (!brief.value) {
    console.debug('acceptNDA : no brief')
    return
  }

  if (showPreview.value) {
    // no changes when preview tab is showing.
    console.log('acceptNDA : action disabled when preview is active')
    return
  }

  if (!accountId.value) {
    alert('You must be logged in to accept the NDA')
    return
  }
  isLoading.value = true

  // calling api to accept NDA to db
  await acceptSmartBriefNDA(ucanStore, brief.value.id, '')

  // add the ucanToken from the 'candidate' for the 'briefOwner'
  await candidateProfileStatus(accountId.value, 'pending')

  // we need a delegated token in this case
  // [TEMP]: Should be... ucanStore.notifyUsingDelegatedUCAN
  await ucanStore.notifyUsingBriefCandidateProfileUCAN(
    accountId.value,
    brief.value.owner,
    brief.value,
    {
      workflowId: 'to-brand-creator-applied-to-brief',
      payload: {
        briefName: brief.value.title,
        briefLink: briefUrl.value,
        creatorId: accountId.value
      }
    }
  )
  sendState({ type: 'SET_ACCEPTED_STATUS', accepted_status: true })
  await refreshBrief()
}

const rejectNDA = () => {
  if (showPreview.value) {
    // no changes when preview tab is showing.
    console.log('rejectNDA : action disabled when preview is active')
    return
  }

  if (!accountId.value) {
    alert('You must be logged in to accept the NDA')
    return
  }
  sendState({ type: 'SET_ACCEPTED_STATUS', accepted_status: false })
}

/**
 * Helper function to determine the target wallet ID.
 * Returns the wallet ID of the opposite party in a transaction, or null if conditions are not met.
 * @param {string} candidateId - Candidate's ID.
 * @param {string} accountId - Account ID.
 * @param {string} briefOwner - Brief owner's ID.
 * @returns {string|null}
 */
const determineTargetWalletId = (candidateId: string, accountId: string, briefOwner: string) => {
  if (accountId === candidateId) {
    return briefOwner
  } else if (accountId === briefOwner) {
    return candidateId
  }
  console.warn(
    `Unexpected account IDs: accountId=${accountId}, candidateId=${candidateId}, briefOwner=${briefOwner}`
  )
  return null
}

/**
 * Retrieves an existing UCAN token between a candidate and the owner of a brief.
 * Returns the token if found, or null if no matching token is found.
 * @param {string} candidateId - Candidate's ID.
 * @returns {Object|null}
 */
const getUcanTokenBetweenCandidateAndBriefOwner = async (candidateId: string) => {
  if (!brief.value) {
    console.debug('getUcanTokenBetweenCandidateAndBriefOwner : no brief')
    return
  }

  const ucanTokens = Array.isArray(loggedInUserCandidateProfile?.value?.ucanTokens)
    ? loggedInUserCandidateProfile?.value?.ucanTokens
    : []

  const ucanAudienceWalletId = determineTargetWalletId(
    candidateId,
    accountId.value,
    brief.value.owner
  )

  if (!ucanAudienceWalletId) {
    console.warn(`No target wallet ID found for candidateId=${candidateId}`)
    return null
  }

  const profileDataRecord = profileData.value || ({} as ProfileData)

  return ucanTokens.find(
    (ucanToken) =>
      ucanToken.iss === profileDataRecord?.didKey &&
      ucanStore
        .parseNotificationsUcanToken(ucanToken.token)
        ?.payload?.fct?.some((fct) => fct?.audienceAccountName === ucanAudienceWalletId)
  )
}

/**
 * Generates a new UCAN token between a candidate and the owner of a brief if one doesn't already exist.
 * Returns the newly created token or null if the token creation fails.
 * @param {string} candidateId - Candidate's ID.
 * @returns {Object|null}
 */
const generateUcanTokenBetweenCandidateAndBriefOwner = async (candidateId: string) => {
  if (!brief.value) {
    console.debug('generateUcanTokenBetweenCandidateAndBriefOwner : no brief')
    return
  }

  const ucanAudienceWalletId = determineTargetWalletId(
    candidateId,
    accountId.value,
    brief.value.owner
  )

  if (!ucanAudienceWalletId) {
    console.warn(`No target wallet ID found for candidateId=${candidateId}`)
    return null
  }

  const hasUcanToken = await getUcanTokenBetweenCandidateAndBriefOwner(candidateId)

  if (!hasUcanToken || !hasUcanToken.id) {
    const profileDataRecord = profileData.value || ({} as ProfileData)

    try {
      const ucanAudienceDetails = await ucanStore.fetchProfile(ucanAudienceWalletId)
      if (!ucanAudienceDetails?.data) {
        console.warn(`Failed to fetch profile for wallet ID: ${ucanAudienceWalletId}`)
        return null
      }
      const ucanToken = await ucanStore.issueNotificationsUcan({
        audienceDid: ucanAudienceDetails.data.didKey,
        audienceWalletId: ucanAudienceDetails.data.id,
        issuerEmailrsa: profileDataRecord?.emailrsa
      })

      if (ucanToken) {
        return {
          id: uuidv4(),
          iss: profileDataRecord?.didKey,
          aud: ucanAudienceDetails.data.didKey,
          token: ucanToken
        }
      } else {
        console.warn('Failed to issue a new UCAN token')
        return null
      }
    } catch (error) {
      console.error('Error generating UCAN token: ', error)
      return null
    }
  }

  return null
}

const candidateProfileStatus = async (
  candidateId: string,
  status:
    | 'accept'
    | 'accepted'
    | 'approved'
    | 'reject'
    | 'rejected'
    | 'shortlisted'
    | 'pending'
    | 'unpaid',
  feedback: string = ''
) => {
  const isPending = status === 'pending'
  const isApproved = status === 'approved' || status === 'accept' || status === 'accepted'
  const isRejected = status === 'rejected' || status === 'reject'
  const hasFeedback = feedback && feedback !== ''

  if (!brief.value) {
    console.debug('candidateProfileStatus : no brief')
    return
  }

  if (!accountId.value) {
    alert('You must be logged in to accept candidate')
    return
  }

  isLoading.value = true
  const data: {
    briefId: string
    userId: string
    status: string
    feedback?: string
    ucanId?: string
    iss?: string
    aud?: string
    token?: string
    [key: string]: unknown
  } = {
    briefId: brief.value.id,
    userId: candidateId,
    status: status
  }

  const feedbackUuid = uuidv4()
  if (feedback && feedback !== '') {
    data['feedback'] = feedback
  }

  if (
    (isPending && candidateId === accountId.value) ||
    (isApproved && brief.value.owner === accountId.value)
  ) {
    const ucanToken = await generateUcanTokenBetweenCandidateAndBriefOwner(candidateId)
    if (ucanToken) {
      data['ucanId'] = ucanToken.id
      data['iss'] = ucanToken.iss
      data['aud'] = ucanToken.aud
      data['token'] = ucanToken.token
    }
  }

  // calling api to update candidate profile status to db
  await updateSmartBriefProfileStatus(ucanStore, data)

  if (isPending) {
    // do nothing...
  } else if (isApproved) {
    sendState({ type: 'CANDIDATE_ACCEPT' })
  } else {
    sendState({ type: 'CANDIDATE_REJECT' })
  }

  // use the token the user set
  if (hasFeedback) {
    await ucanStore.notifyUsingBriefCandidateProfileUCAN(
      brief.value?.owner,
      candidateId,
      brief?.value,
      {
        workflowId: 'smartbrief-content-feedback',
        payload: {
          briefName: brief.value.title,
          briefLink: briefUrl.value,
          briefSubmissionDeadline: (brief.value?.submissionDeadline || '')
            .split('/')
            .reverse()
            .join('/'),
          brand: brief.value.brandName,
          feedback: feedback,
          feedbackUuid: feedbackUuid
        }
      }
    )
  } else if (isApproved) {
    await ucanStore.notifyUsingBriefCandidateProfileUCAN(
      brief.value?.owner,
      candidateId,
      brief?.value,
      {
        workflowId: 'smartbrief-candidate-accepted',
        payload: {
          briefName: brief.value.title,
          briefLink: briefUrl.value
        }
      }
    )
  } else if (isRejected) {
    await ucanStore.notifyUsingBriefCandidateProfileUCAN(
      brief.value?.owner,
      candidateId,
      brief?.value,
      {
        workflowId: 'smartbrief-candidate-rejected',
        payload: {
          briefName: brief.value.title,
          briefLink: briefUrl.value,
          viewAllBriefs:
            window.location.protocol + '//' + window.location.hostname + '/briefs/?tab=all'
        }
      }
    )
  }

  await refreshBrief()
}

const uploadCandidateVideoToBunnyStream = async (
  file: File,
  candidateId: string,
  briefObject: SmartBrief
) => {
  // set the data as we go
  let VideoId
  let LibraryId

  // ensure we have a file
  if (!file || !(file instanceof File)) {
    throw new Error('File is required')
  }

  // get the name of the file
  const { extension } = getFileNameAndExtension(file.name)
  if (extension && !isVideo(extension.toLowerCase())) {
    throw new Error('File is not a video ' + extension.toLowerCase())
  }

  // get the ucan token
  const ucanToken = await ucanStore.extractCandidateToBriefOwnerUCAN(
    candidateId,
    briefObject.owner,
    briefObject
  )

  // set up uppy
  const uppyInstance = new Uppy()

  uppyInstance.setOptions({
    restrictions: {
      allowedFileTypes: ['application/x-mpegURL', 'video/*'],
      maxNumberOfFiles: 1,
      maxFileSize: 500 * 1000000
    }
  })

  uppyInstance.use(Tus, {
    endpoint: 'https://video.bunnycdn.com/tusupload',
    removeFingerprintOnSuccess: true,
    retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
    async onBeforeRequest(req, file) {
      // fetch the headers we need to make this request
      const signature = await ucanStore.uploadVideoToBunnyUsingTus(
        'briefs',
        briefObject.id,
        'sign',
        file,
        ucanToken
      )

      // set the headers
      req.setHeader('AuthorizationSignature', signature.data.AuthorizationSignature) // SHA256 signature (library_id + api_key + expiration_time + video_id)
      req.setHeader('AuthorizationExpire', signature.data.AuthorizationExpire) // Expiration time as in the signature,
      req.setHeader('VideoId', signature.data.VideoId) // The guid of a previously created video object through the Create Video API call
      req.setHeader('LibraryId', signature.data.LibraryId)

      // set some data
      VideoId = signature.data.VideoId
      LibraryId = signature.data.LibraryId
    }
  })

  uppyInstance.on('file-added', async (file) => {
    // start the 'briefs' video upload process
    ucanStore.uploadVideoToBunnyUsingTus('briefs', briefObject.id, 'start', file, ucanToken)
  })

  // add the file to uppy
  uppyInstance.addFile({
    name: file.name,
    type: file.type,
    data: file
  })

  // log the upload progress
  uppyProgress.value = 0
  uppyBusy.value = true
  uppyInstance.on('progress', (progress) => {
    uppyProgress.value = progress
    uploadMessage.value = 'Creating video stream ' + progress + '%'
    if (progress === 100) {
      uppyBusy.value = false
      uploadMessage.value = 'Saving submission...'
    }
  })

  // do the upload
  const videoUpload = await uppyInstance.upload()

  // look for this file in the successful response
  const uploadCompleted = videoUpload?.successful?.find((v) => v.name === file.name)

  // throw an error if this did not work
  if (!uploadCompleted?.progress?.uploadComplete) {
    throw new Error('Failed to upload file.')
  }

  // return the VideoId and LibraryId
  return {
    VideoId: VideoId,
    LibraryId: LibraryId
  }
}

const submitContent = async (uplodedContent?: any) => {
  if (uplodedContent) {
    UploadRefLogo.value = uplodedContent
  }
  if (!brief.value) {
    console.debug('submitContent : no brief')
    return
  }

  if (showPreview.value) {
    // no changes when preview tab is showing.
    console.log('submitContent : action disabled when preview is active')
    return
  }

  if (UploadRefLogo.value) {
    const extension = UploadRefLogo.value.ipfs_media_type
    console.log('extension: ', extension)
    console.log('isImage: ', isImage(extension.toLowerCase()))
    console.log('isVideo: ', isVideo(extension.toLowerCase()))
    if (extension && isImage(extension.toLowerCase())) {
      isLoading.value = true

      if (UploadRefLogo.value.file_url) {
        const data = {
          owner: accountId.value,
          briefId: brief.value.id,
          file_url: UploadRefLogo.value.ipfs_file_url,
          watermark_file_url: UploadRefLogo.value.ipfs_watermark_file_url,
          iv: UploadRefLogo.value.iv,
          key: UploadRefLogo.value.key,
          keyBriefOwner: UploadRefLogo.value.key_brief_owner,
          media_type: UploadRefLogo.value.ipfs_media_type,
          cid: '',
          cdn_file_url: UploadRefLogo.value.file_url,
          name: UploadRefLogo.value.name,
          description: UploadRefLogo.value.description,
          tags: UploadRefLogo.value.tags
        }

        // calling api to add smartbrief content to db
        // data['cid'] = cidString
        await addSmartBriefContent(ucanStore, data)

        // send pending state, otherwise this won't happen on your second upload after a first has been rejected

        // feedback is not refreshed without reloading the page to get new feedback dates, allow this to trip to pending for now.
        sendState({ type: 'CANDIDATE_CONTENT_UPLOAD' })

        // notify brand owner that we have more data
        await ucanStore.notifyUsingBriefCandidateProfileUCAN(
          accountId.value,
          brief.value.owner,
          brief.value,
          {
            workflowId: 'to-brand-creator-submitted-content',
            payload: {
              briefName: brief.value.title,
              contentId: uuidv4(),
              creatorId: accountId.value,
              creatorName: profileData.value?.firstName || profileData.value?.firstname || '',
              briefLink: briefUrl.value + '/submissions'
            }
          }
        )
      }
    } else if (extension && isVideo(extension.toLowerCase())) {
      isLoading.value = true

      /* let uploaded
      try {
        uploaded = await uploadCandidateVideoToBunnyStream(
          UploadRefLogo.value.fileList[0].file,
          accountId.value,
          brief.value
        )
        console.log(
          'uploadCandidateVideoToBunnyStream',
          uploaded,
          `https://iframe.mediadelivery.net/play/${uploaded.LibraryId}/${uploaded.VideoId}`
        )
      } catch (err) {
        console.debug('error', err, 'Possible no available ucann tokens on the candidates')
        isLoading.value = false
        return
      } */

      if (UploadRefLogo.value.file_url) {
        const data: {
          owner: string
          briefId: string
          file_url: string
          watermark_file_url?: string
          iv?: string
          key?: string
          keyBriefOwner?: string
          media_type?: string
          watermark_video_stream_url?: string
          cdn_file_url?: string
          name?: string
          description?: string
          tags?: string
          [key: string]: unknown
        } = {
          owner: accountId.value,
          briefId: brief.value.id,
          file_url: UploadRefLogo.value.ipfs_file_url,
          watermark_file_url: UploadRefLogo.value.ipfs_watermark_file_url,
          iv: UploadRefLogo.value.iv,
          key: UploadRefLogo.value.key,
          keyBriefOwner: UploadRefLogo.value.key_brief_owner,
          media_type: UploadRefLogo.value.ipfs_media_type,
          cdn_file_url: UploadRefLogo.value.file_url,
          watermark_video_stream_url: UploadRefLogo.value.file_url,
          name: UploadRefLogo.value.name,
          description: UploadRefLogo.value.description,
          tags: UploadRefLogo.value.tags
        }

        /* if (uploaded) {
          data['watermark_video_stream_url'] =
            `https://iframe.mediadelivery.net/play/${uploaded.LibraryId}/${uploaded.VideoId}`
        } */

        // calling api to add smartbrief content to db
        // data['cid'] = cidString
        await addSmartBriefContent(ucanStore, data)

        // send pending state, otherwise this won't happen on your second upload after a first has been rejected

        // feedback is not refreshed without reloading the page to get new feedback dates, allow this to trip to pending for now.
        // dontReject.value = true;
        sendState({ type: 'CANDIDATE_CONTENT_UPLOAD' })

        // notify brand owner that we have more data
        await ucanStore.notifyUsingBriefCandidateProfileUCAN(
          accountId.value,
          brief.value.owner,
          brief.value,
          {
            workflowId: 'to-brand-creator-submitted-content',
            payload: {
              briefName: brief.value.title,
              contentId: uuidv4(),
              creatorId: accountId.value,
              creatorName: profileData.value?.firstName || profileData.value?.firstname || '',
              briefLink: briefUrl.value + '/submissions'
            }
          }
        )
      }
    } else {
      await confirmDialog.value?.show({
        title: 'Upload Content',
        message: `Content upload error`,
        okButton: 'Close'
      })
    }
    await refreshBrief('submitContent')
  }
}

function convertToJsonArray(str: string) {
  try {
    if (str !== '[]') {
      const strJson = JSON.parse(str)
      // const exampleMedia = strJson.example_media
      // let validJsonString = exampleMedia.replace(/'/g, '"')
      // let actualArray = JSON.parse(validJsonString)
      return strJson
    } else {
      return []
    }
  } catch (e) {
    if (typeof str === 'string') {
      return str
    }
    console.info('Failed to parse string into JSON:', str)
    return []
  }
}

const convertNewLine = (content: string | undefined) => {
  // return content.replace(/\^newline\^/g, '\n\n')
  return content?.replace(/\n/g, '<br/>')
}

const briefUrl = computed(() => baseUrl() + '/briefs/' + brief.value?.id)

const loggedInUserCandidateProfile = computed(() => {
  return brief.value?.candidateProfiles?.find((item) => item.userId === accountId.value)
})

const loggedInUserCandidateSubmissions = computed(() => {
  const myProfile = brief.value?.candidateProfiles?.filter(
    (item) => item.userId === accountId.value
  )
  let submissions: any[] = []
  if (myProfile) {
    submissions = myProfile[0]?.contents?.filter((item) => item.owner === accountId.value)
    submissions = submissions ? [...submissions] : []
    submissions = sortByDateProperty(submissions, 'createdAt', 'asc')
  }
  return submissions
})

// for scripts
const lDate = (f: string) => {
  return new Date(parseInt(f)).toLocaleString()
}

const latestFeedbackDate = ref()

const loggedInUserCandidateLatestFeedback = computed(() => {
  let feedback: SmartBriefFeedback[] = []

  let candidateFeedbacks = brief.value?.candidateProfiles?.find(
    (item) => item.userId === accountId.value
  )?.feedbacks
  if (candidateFeedbacks && candidateFeedbacks.length > 0) {
    feedback = feedback.concat(candidateFeedbacks)
  }

  let contentFeedbacks = brief.value?.candidateProfiles
    ?.find((item) => item.userId === accountId.value)
    ?.contents.flatMap((content) => content.feedbacks)
  if (contentFeedbacks && contentFeedbacks.length > 0) {
    feedback = feedback.concat(contentFeedbacks)
  }

  feedback = feedback ? sortByDateProperty(feedback, 'createdAt', 'asc') : feedback

  feedback?.forEach((f) => {
    latestFeedbackDate.value = lDate(`${f.createdAt}`)
  })

  return feedback?.pop()
})

const loggedInUserCandidateLatestSubmissions = computed(() => {
  let submissions = loggedInUserCandidateSubmissions.value
  submissions = submissions ? [...submissions] : []
  submissions = sortByDateProperty(submissions, 'createdAt', 'asc')

  let d = submissions?.pop()
  if (d) {
    return d
  }
  return null
})

const loggedInUserCandidateHasFeedback = computed(() => {
  // this gets called multiple times, so we musn't pop elements off the original list
  let d1 = new Date(`${loggedInUserCandidateLatestFeedback.value?.createdAt || 0}`),
    d2 = new Date(`${loggedInUserCandidateLatestSubmissions.value?.createdAt || 0}`)
  return d1 > d2
})

const haveApprovedCandidates = computed(() => {
  return (
    (brief?.value?.candidateProfiles || [])?.filter((item) => item?.status === 'approved')?.length >
    0
  )
})

const loggedInUserAcceptedSubmissions = computed(() => {
  const myProfile = brief?.value?.candidateProfiles?.filter(
    (profile) =>
      profile.userId === accountId.value && brief?.value?.acceptedProfiles?.includes(profile.userId)
  )
  let submissions: any[] = []
  if (myProfile) {
    submissions = myProfile[0]?.contents?.filter((item) => item.owner === accountId.value)
    submissions = submissions ? [...submissions] : []
    submissions = sortByDateProperty(submissions, 'createdAt', 'asc')
  }
  return submissions
})

const isClosedBrief = computed(() => {
  if (brief?.value?.submissionDeadline) {
    const now = new Date()
    const deadline = new Date(brief?.value?.submissionDeadline)
    const fiveDaysAfterDeadline = new Date(deadline.getTime() + 10 * 24 * 60 * 60 * 1000)
    return now > fiveDaysAfterDeadline
  } else {
    return false
  }
})

/*
    State changes and menu visibility for creators:
      [x] When an NDA version of a brief is opened by a creator, only the details tab is visible.
      [x] When they accept the NDA then the Q&A tab becomes visible.
      [x] When they get accepted by the brand, the submissions tab becomes visible.
      [x] When they do their first submission, the licensed content tab becomes visible.

    State changes and menu visibility for brands:
      [x] Only the details tab is visible before publishing.
      [x] Once the brief is published, Q&A and candidates become visible.
      [x] Once the first candidate is approved, submissions are made visible.
      [x] Once the first content is approved, licensed content is made visible.
      [ ] Also a “details before NDA” tab is shown if the brief is with NDA which shows the brand the brief before the NDA in read-only mode, so they can easily check what others see.
*/

const getBriefHref = (brief_id?: string, active_tab?: string) => {
  return brief_id
    ? $router.resolve({
        name: 'briefs.briefdetails',
        params: {
          brief_id,
          active_tab
        }
      }).href
    : undefined
}

const tabMenuItems = computed(() => {
  // menu items
  let menuItems = [
    {
      id: 'details',
      label: 'Details',
      selected: false,
      show: true,
      clickable: true,
      href: getBriefHref(brief.value?.slug || brief.value?.id)
    }
  ]

  if (
    pathname.value && // used to re-render the menu when the path changes
    accountId.value &&
    isPublished.value &&
    (isBrandView.value || (isCreatorView.value && isNDASubmitted.value))
  ) {
    menuItems = [
      ...menuItems,
      {
        id: 'candidates',
        label: 'Candidates',
        selected: false,
        show: !!isBrandView.value,
        clickable: true,
        href: getBriefHref(brief.value?.slug || brief.value?.id, 'candidates')
      },
      {
        id: 'qa',
        label: 'Q&A',
        selected: false,
        show:
          isNDASubmitted.value ||
          (isBrandView.value && haveApprovedCandidates?.value) ||
          (isCreatorView.value && loggedInUserCandidateProfile?.value?.status === 'approved'),
        clickable: true,
        href: getBriefHref(brief.value?.slug || brief.value?.id, 'qa')
      },
      {
        id: 'messages',
        label: 'Messages',
        selected: false,
        show:
          isCreatorView.value &&
          (!!loggedInUserCandidateLatestFeedback.value?.createdAt ||
            loggedInUserCandidateProfile?.value?.status === 'approved'),
        clickable: true,
        href: getBriefHref(brief.value?.slug || brief.value?.id, 'messages')
      },
      {
        id: 'submissions',
        label: 'Submissions',
        selected: false,
        show:
          (isBrandView.value && haveApprovedCandidates?.value) ||
          (isCreatorView.value && loggedInUserCandidateProfile?.value?.status === 'approved'),
        clickable: true,
        href: getBriefHref(brief.value?.slug || brief.value?.id, 'submissions')
      },
      {
        id: 'licencedcontent',
        label: 'Licensed Content',
        selected: false,
        show:
          (isBrandView.value && (brief?.value?.acceptedProfiles?.length || 0) > 0) ||
          (isCreatorView.value && loggedInUserCandidateSubmissions?.value?.length > 0),
        clickable: true,
        href: getBriefHref(brief.value?.slug || brief.value?.id, 'licencedcontent')
      }
    ]
  }

  // filter items we are not showing
  let visibleMenuItems = menuItems.filter((item) => !!item.show)

  // If only "Details" tab is visible, make it not clickable
  if (visibleMenuItems.length === 1 && visibleMenuItems[0].id === 'details') {
    visibleMenuItems[0].clickable = false
  }

  // Map items to set selected state
  return visibleMenuItems.map((item) => ({
    ...item,
    selected: currentTab.value === item.id
  }))

  // filter items we are not showing
  /* return menuItems
    .filter((item) => !!item.show)
    .map((item) => ({
      ...item,
      selected: currentTab.value === item.id
    })) */
})

const briefsmenu = ref(currentTab.value)

const separateFiles = (files: any[]): { images: any[]; docs: any[] } => {
  const images: File[] = []
  const docs: File[] = []

  files.forEach((file) => {
    if (file.file_url) {
      const fileType = getFileType(file.file_url)
      // we handle image and video in same component
      if (fileType === 'image' || fileType === 'video') {
        images.push(file)
      } else if (fileType === 'doc') {
        docs.push(file)
      }
    }
  })

  return { images, docs }
}

const modelReleaseFile = computed(() => {
  const modelRelease = JSON.parse(brief.value?.modelRelease || '[]')
  if (Array.isArray(modelRelease) && modelRelease.length > 0) {
    return modelRelease[0]?.file_url || ''
  }
  return ''
})

const preProductionFile = computed(() => {
  const preProduction = JSON.parse(brief.value?.preProduction || '[]')
  if (Array.isArray(preProduction) && preProduction.length > 0) {
    return preProduction[0]?.file_url || ''
  }
  return ''
})

const preProductionFiles = computed(() => {
  try {
    const preProduction = JSON.parse(brief.value?.preProduction || '[]')
    if (Array.isArray(preProduction) && preProduction.length > 0) {
      const { images, docs } = separateFiles(preProduction)
      return { images, docs }
    }
  } catch (err) {
    console.error(err)
  }
  return { images: [], docs: [] }
})

const imageFiles = computed(() => preProductionFiles.value.images)
const docFiles = computed(() => preProductionFiles.value.docs)

const hasWebpSupported = ref<boolean>(false)
isWebpSupported('lossy').then((result) => {
  hasWebpSupported.value = result.result
})

const combinedSections = computed(() => {
  let contentRights = brief.value?.contentRights
  if (contentRights && modelReleaseFile.value !== '') {
    contentRights = `${contentRights}<div class="mb-8"></div><div style="display: flex; justify-content: center; width: 100%;"><div class="py-1.5 px-6 min-w-28 border-2 border-[#FFFFFF] text-[#FFFFFF] rounded-full hover:bg-[#FFFFFF] hover:text-[#FF008B] text-center" style="width: 250px;"><a href="${modelReleaseFile.value}" target="_blank" class="text-[#FFFFFF] hover:text-[#FF008B]">Download model release</a></div></div>`
  }

  const ext = hasWebpSupported.value ? 'webp' : 'png'

  const hardcodedSections = [
    {
      title: 'About the brand',
      content: brief.value?.aboutTheBrand,
      type: '',
      image: `/images/brief-details-page/woman-touching-vr-headset.${ext}`,
      items: ''
    },
    {
      title: 'Ideal candidate',
      content: brief.value?.idealCandidate,
      type: 'Image on the left and text on the right',
      image: `/images/brief-details-page/smile-camera-portrait.${ext}`,
      items: ''
    },
    {
      title: 'Things to consider',
      content: brief.value?.considerations,
      type: '',
      image: `/images/brief-details-page/cheerful-guy-using-smartphone.${ext}`,
      items: ''
    },
    {
      title: 'Technical requirements',
      content: brief.value?.techRequirements,
      type: 'Image on the left and text on the right',
      image: `/images/brief-details-page/woman-with-disability-taking-a-selfie-with-her-smartphone.${ext}`,
      items: ''
    },
    {
      title: 'Content rights',
      content: contentRights,
      type: '',
      image: `/images/brief-details-page/delighted-female-with-gentle-smile-holds-phone.${ext}`,
      items: ''
    },
    {
      title: 'Rewards',
      content: brief.value?.rewards,
      type: 'Image on the left and text on the right',
      image: `/images/brief-details-page/portrait-of-mature-female-videographer-with-camera.${ext}`,
      items: ''
    },
    {
      title: 'Deliverables',
      content: brief.value?.deliverables,
      type:
        (imageFiles.value && imageFiles.value.length > 0) ||
        (docFiles.value && docFiles.value.length > 0)
          ? 'Text on left and carousel on right'
          : '',
      image: `/images/brief-details-page/young-girl-making-selfie-and-smiling.${ext}`,
      imageItems: imageFiles.value,
      fileItems: docFiles.value
    }
  ]

  const allSections = [...hardcodedSections]

  // Filter out empty sections
  return allSections.filter((section) => section.content && section.content.trim() !== '')
})

const onPublish = async () => {
  if (!brief.value) {
    console.debug('onPublish : no brief')
    return
  }

  if (showPreview.value) {
    // no changes when preview tab is showing.
    console.log('onPublish : action disabled when preview is active')
    return
  }
  isLoading.value = true

  // calling api to publish brief to db
  await publishSmartBrief(ucanStore, brief.value.id)

  await ucanStore.notifyUsingSelfSignedUCAN(accountId.value, {
    workflowId: 'smartbrief-to-brand-you-published-a-brief',
    payload: {
      briefId: brief.value.id,
      briefLink: briefUrl.value,
      briefName: brief.value.title
    }
  })
  await refreshBrief()
}

/*
const onUnpublish = async () => {
  if (!brief.value) {
    console.debug('onUnpublish : no brief')
    return
  }

  if (showPreview.value) {
    // no changes when preview tab is showing.
    console.log('onUnpublish : action disabled when preview is active')
    return
  }
  isLoading.value = true

  // calling api to unpublish brief to db
  await unpublishSmartBrief(ucanStore, brief.value.id)

  await refreshBrief()
}

const onArchive = async () => {
  isLoading.value = true
  const data = {}
  data['brief_id'] = brief.value.id
  data['archive'] = !brief.value.archive
  const { cid } = await pinDataToIPFS(JSON.stringify(data))
  const result = await nearWalletStore.archiveBrief(cid.toString())
  if (result === API_RESULT_NO_NEAR) {
    isLoading.value = false
    await confirmDialog.value?.show({
      title: 'Error',
      message: 'You need to have at least 0.1 NEAR in your wallet to perform this action.',
      okButton: 'OK'
    })
    return
  } else {
    isLoading.value = false
    confirmDialog.value?.show({
      title: 'Archive Done',
      message: 'Archive Done!',
      okButton: 'Ok'
    })
    setTimeout(() => {
      window.location.href =
        window.location.protocol + '//' + window.location.hostname + '/briefs/?tab=my'
    }, 5000)
  }
}
*/

const getTitle = () => {
  if (!brief.value) return ''

  if (isAccepted.value) {
    return brief.value.title
  }
  return brief.value.ndaTitle
}

const setPreviewState = (e: Event) => {
  previewState.value = (e.target as HTMLSelectElement).value
}

watch(briefsmenu, (nv, ov) => {
  if (ov && nv !== ov && brief.value) {
    $router.push({
      name: 'briefs.briefdetails',
      params: {
        brief_id: brief.value?.slug || brief.value.id,
        active_tab: nv
      }
    })
  }
})

watch(isLoading, (nv) => {
  // remove any test state
  if (nv) {
    hidePreview()
  }
})

watch(isBrandView, (nv) => {
  if (nv && showPreview.value) {
    isCreatorView.value = false
  }
})

watch(isCreatorView, (nv) => {
  if (nv && showPreview.value) {
    isBrandView.value = false
  }
})

watch(showPreview, (nv) => {
  if (nv) {
    const el = window.document.getElementById('previewStateSelect')
    if (el) {
      el.innerHTML = JSON.stringify(briefMachineActor.getSnapshot())
    }
  }
})

watchEffect(() => {
  const newState = briefMachineActor.getSnapshot()

  if (showDebug.value) {
    console.log('state changed', newState.context.data)
  }

  // this is a hack, data.brief does not exist so its always true
  if (newState.matches('setNDAStatus')) {
    isNDASubmitted.value = true
  } else if (newState.matches('setAcceptedStatus')) {
    isNDAAccepted.value = true
  } else {
    // either there is no NDA or its accepted, otherwise tabs get hidden
    isNDASubmitted.value = true
  }

  if (isNDAAccepted.value) {
    isNDASubmitted.value = true
  }

  if (isNDASubmitted.value) {
    isNDAChecked.value = true
  }

  // redirect to appropriate tab
  if (!props.active_tab) {
    if (loggedInUserCandidateLatestFeedback.value?.createdAt) {
      currentTab.value = 'messages'
    }
    if (
      loggedInUserCandidateSubmissions.value.length > 0 ||
      loggedInUserCandidateProfile?.value?.status === 'approved'
    ) {
      currentTab.value = 'submissions'
    }
    if (loggedInUserAcceptedSubmissions.value.length > 0) {
      currentTab.value = 'licencedcontent'
    }
  }
})

// watch the path and when it changes re-render and update the active tab
watch(
  () => $route.fullPath,
  (nv, ov) => {
    if (nv !== ov) {
      currentTab.value = String($route.params?.active_tab || 'details')
      myWatch()
    }
  }
)

const myWatch = () => {
  pathname.value = window.location.pathname

  if (showPreview.value) {
    // no changes when preview tab is showing.
    console.log('showPreview active, not calculating state')
    return
  }

  if (showDebug.value) {
    console.log('myWatch', refreshCount.value)
  }

  // console.log(props.brief_id);
  params.id = props.brief_id
  params.accountId = accountId.value

  if (profileData.value) {
    isProfileFilled.value = !!profileData.value
  }

  if (briefData.value) {
    brief.value = {
      ...briefData.value
    }

    if (!brief.value) {
      console.debug('myWatch : no brief')
      return
    }

    if (brief.value?.paymentPreference) {
      isPaymentPreferenceFilled.value = brief.value?.paymentPreference != null
    }

    if (briefMachineActor.getSnapshot().value === 'start') {
      sendState({
        type: 'START',
        brief: {
          ...(brief.value || {})
        }
      })
    }

    if (accountId.value !== '') {
      if (brief.value.ndaSubmitted) {
        // isAccepted.value = brief.value.ndaSubmitted.filter((e) => e === accountId.value).length > 0
        isAccepted.value =
          brief.value.ndaSubmitted.includes(accountId.value) ||
          brief.value.ndaSubmitted.includes('non-logged-in-user')
      }
      isAccepted.value = isAccepted.value || brief.value.owner === accountId.value
    } else {
      // consider NDA submitted when user is non-loggedin
      if (brief.value.ndaSubmitted) {
        isAccepted.value = brief.value.ndaSubmitted.includes('non-logged-in-user')
      }
    }

    if (brief.value.visibility === 'public') {
      isAccepted.value = true
      sendState({ type: 'SET_NDA_STATUS', nda_status: true })
      if (brief.value.ndaAccepted) {
        const myAcceptedId = brief.value.ndaAccepted.find((item) => item === accountId.value)
        if (myAcceptedId) {
          sendState({ type: 'SET_ACCEPTED_STATUS', accepted_status: true })
        }
      }
    }

    let metaDataValue = convertToJsonArray(brief.value?.metaData || '[]')
    metaData.value = metaDataValue
    // let contentRightsValue = convertToJsonArray(brief.value?.contentRights || '[]')
    // contentRights.value = brief.value?.contentRights
    // let deliverablesValue = convertToJsonArray(brief.value?.deliverables || '[]')
    // deliverables.value = brief.value?.deliverables

    // if the owner is looking at the brief we are in brand view, unless overridden in preview
    if (!showPreview.value) {
      isBrandView.value = brief.value.owner === accountId.value || teamStore.can('team.briefs.list')
      isCreatorView.value = !isBrandView.value
      isPublished.value = brief.value.status === 'published'
    }

    if (isAccepted.value) {
      briefImage.value = brief.value.image
    } else {
      briefImage.value = brief.value.ndaImage
    }

    if (accountId.value !== '') {
      // const myId = brief.value?.ndaSubmitted?.find((item) => item === accountId.value)
      const myId = brief.value?.ndaSubmitted?.find(
        (item) => item === accountId.value || item === 'non-logged-in-user'
      )
      if (myId || brief.value.visibility === 'public') {
        sendState({ type: 'SET_NDA_STATUS', nda_status: true })
        // check if its accepted
        const myAcceptedId = brief.value?.ndaAccepted?.find((item) => item === accountId.value)
        if (myAcceptedId) {
          sendState({ type: 'SET_ACCEPTED_STATUS', accepted_status: true })
        }
      }
    } else {
      const myId = brief.value?.ndaSubmitted?.find((item) => item === 'non-logged-in-user')
      if (myId || brief.value.visibility === 'public') {
        sendState({ type: 'SET_NDA_STATUS', nda_status: true })
      }
    }

    if (
      briefMachineActor.getSnapshot().value === 'candidateReview' ||
      briefMachineActor.getSnapshot().value === 'candidateUpload' ||
      briefMachineActor.getSnapshot().value === 'candidateUploadReview'
    ) {
      // check whether candidate has been accepted or rejected
      if (loggedInUserCandidateProfile?.value?.status) {
        if (loggedInUserCandidateProfile.value.status === 'approved') {
          sendState({ type: 'CANDIDATE_ACCEPT' })
        } else if (loggedInUserCandidateProfile.value.status === 'rejected') {
          sendState({ type: 'CANDIDATE_REJECT' })
        }
      }

      if (loggedInUserCandidateHasFeedback.value === true) {
        if (brief?.value?.acceptedProfiles?.find((item) => item === accountId.value)) {
          // when the user is in acceptedProfiles it means their submission has been accepted
          sendState({ type: 'CANDIDATE_CONTENT_UPLOAD_ACCEPT' })
        } else {
          // when we have feedback later than the content that means the user can upload more
          sendState({ type: 'CANDIDATE_CONTENT_UPLOAD_REJECT' })
        }
      } else {
        // check if the candidate has submitted content
        if (loggedInUserCandidateSubmissions?.value?.length > 0) {
          // this means they have uploaded content
          sendState({ type: 'CANDIDATE_CONTENT_UPLOAD' })
          if (brief?.value?.acceptedProfiles?.find((item) => item === accountId.value)) {
            // when the user is in acceptedProfiles it means their submission has been accepted
            sendState({ type: 'CANDIDATE_CONTENT_UPLOAD_ACCEPT' })
          }
        }
      }
    }

    // sendState takes time and makes the page flicker
    setTimeout(() => {
      pageReady.value = true
    }, 1000)
  }
}

onUnmounted(() => {
  briefMachineActor.stop()
})

watchEffect(myWatch)

const linkClass =
  'tab-link cursor-pointer font-medium py-1.5 px-3 text-gray-900 capitalize hover:text-[#FF008B]'

const formatTypes = (v: any) => {
  if (v && typeof v.replaceAll !== 'undefined') {
    return v.replaceAll(',', ', ')
  }
  return v
}
</script>

<template>
  <!-- nda text modal -->
  <div v-if="brief?.ndaText">
    <Modal :show="ndaTextModal" @close="ndaTextModal = false" :closeable="true" max-width="2xl">
      <div class="bg-white p-6 rounded-lg border border-gray-300">
        <div class="w-full font-medium flex justify-between">
          <div>&nbsp;</div>
          <div
            @click.prevent="ndaTextModal = false"
            class="text-gray-400 hover:text-blue-400 cursor-pointer"
          >
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div class="text-md mt-4 mb-2">
          <Markdown :content="extractFileUrl(brief?.ndaText)" />
        </div>
      </div>
    </Modal>
  </div>

  <AlertDialog ref="confirmDialog"></AlertDialog>

  <BriefsLayout :title="$t('studio.briefs.title')" selectedLinkId="briefs">
    <!-- Breadcrumbs -->
    <div class="h-4 -ml-1 mb-3">
      <div
        class="flex items-center"
        v-if="
          teamStore.can('team.briefs.list') &&
          teamStore.team?.uuid &&
          teamStore.team.uuid === briefData?.teamUuid
        "
      >
        <div
          class="mx-1 uppercase tracking-widest font-bold text-xs text-gray-500 hover:text-[#FF008B]"
        >
          <router-link
            :to="{
              name: 'teams.index'
            }"
          >
            <small>Teams</small>
          </router-link>
        </div>
        <div class="mx-1 flex items-start">
          <i class="fa-solid fa-chevron-right text-[50%] text-gray-500"></i>
        </div>
        <div
          class="mx-1 uppercase tracking-widest font-bold text-xs text-gray-500 hover:text-[#FF008B]"
          :key="teamStore.team.name"
        >
          <router-link
            :to="{
              name: 'teams.show',
              params: {
                nickname: teamStore.team.nickname
              }
            }"
          >
            <small>{{ teamStore.team.name }}</small>
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="!isMobile">
      <BriefDetailsHeader
        v-if="brief"
        :brief="brief"
        :brief-image="briefImage"
        :isAccepted="isAccepted"
        :isBrandView="isBrandView"
      />
    </div>

    <div v-if="briefDataFailedToLoad">
      <p class="text-center text-gray-500 text-sm">
        Unable to find this brief. Please check the URL and try again or view all
        <router-link
          :to="{
            name: 'briefs.list'
          }"
        >
          <span class="text-[#FF008B] hover:underline">Briefs</span> </router-link
        >.
      </p>
    </div>
    <div v-else-if="!pageReady || !walletReady">
      <Vue3Lottie :animationData="busy" :height="100" />
    </div>
    <div v-else>
      <!-- debug -->
      <pre v-if="showDebug" class="pt-3 rounded-md bg-white text-xs mt-3" :key="refreshCount">
        refresh [{{ refreshCount }}]
        brief {{ brief?.id }}
        active_tab {{ currentTab }}
        accountId {{ accountId }}
        state {{ briefMachineActor.getSnapshot().value }}
        isAccepted {{ isAccepted }}
        isPublished {{ isPublished }}
        isBrandView {{ isBrandView }}
        isCreatorView {{ isCreatorView }}
        isNDASubmitted {{ isNDASubmitted }}
        isNDAAccepted {{ isNDAAccepted }}
        brief?.contents?.length {{ brief?.contents?.length }}
        loggedInUserCandidateSubmissions?.length {{ loggedInUserCandidateSubmissions?.length }}
        (submissions, not necessarily licensed by brand)
        loggedInUserCandidateHasFeedback {{ loggedInUserCandidateHasFeedback }}
        loggedInUserCandidateLatestFeedback?.createdAt {{
          loggedInUserCandidateLatestFeedback?.createdAt
        }}
        {{ latestFeedbackDate }}
        loggedInUserCandidateLatestSubmissions?.createdAt {{
          loggedInUserCandidateLatestSubmissions?.createdAt
        }}
        {{ lDate(`${loggedInUserCandidateLatestSubmissions?.createdAt}`) }}
        Feedback [TODO]
      </pre>

      <div class="w-full relative md:invisible visible">
        <i class="fa-solid fa-chevron-left text-gray-300 absolute -left-4 top-2.5 z-20"></i>
        <i class="fa-solid fa-chevron-right text-gray-300 absolute -right-4 top-2.5 z-20"></i>
      </div>
      <!-- tab menu -->
      <div v-if="brief" class="flex items-center flex-col md:flex-row md:justify-between">
        <div class="p-2 space-x-2 absolute md:relative invisible md:visible">
          <ul class="flex flex-wrap items-center" v-if="brief">
            <li class="mx-1 my-2" v-for="(item, indx) in tabMenuItems" :key="item.id || indx">
              <template v-if="item.clickable">
                <RouterLink
                  :to="{
                    name: 'briefs.briefdetails',
                    params: {
                      brief_id: brief?.slug || brief?.id,
                      active_tab: item.id
                    }
                  }"
                >
                  <div
                    :class="[
                      'click-tab-' + item.label,
                      linkClass,
                      {
                        selected: item.selected
                      }
                    ]"
                  >
                    {{ item.label }}
                  </div>
                </RouterLink>
              </template>
              <template v-else>
                <div
                  :class="[
                    'click-tab-' + item.label,
                    linkClass,
                    {
                      selected: item.selected,
                      'non-clickable': true
                    }
                  ]"
                >
                  {{ item.label }}
                </div>
              </template>
            </li>
          </ul>
        </div>

        <!-- mobile menu -->
        <div
          id="mobile-menu"
          class="w-full mb-3 h-10 text-center md:hidden border-b-2 border-[#FF008B] relative overflow-hidden overflow-x-scroll"
          v-if="brief"
        >
          <div class="absolute flex whitespace-nowrap">
            <div v-for="(item, indx) in tabMenuItems" :key="item.id || indx">
              <RouterLink
                :to="{
                  name: 'briefs.briefdetails',
                  params: {
                    brief_id: brief?.slug || brief?.id,
                    active_tab: item.id
                  }
                }"
              >
                <div
                  :class="[
                    'click-tab-' + item.label,
                    linkClass,
                    {
                      selected: item.selected
                    }
                  ]"
                >
                  {{ item.label }}
                </div>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>

      <div v-if="isMobile">
        <BriefDetailsHeader
          v-if="brief && briefImage"
          :brief="brief"
          :brief-image="briefImage"
          :isAccepted="isAccepted"
          :isBrandView="isBrandView"
        />
      </div>

      <!-- preview mode -->
      <div
        class="flex my-3 items-center h-[40px] w-full overflow-x-hidden"
        v-if="false && isPreview"
      >
        <div
          class="cursor-pointer text-xs text-center p-1 rounded-md"
          :class="{
            'bg-gray-200': hidePreviewBar,
            hidden: !hidePreviewBar
          }"
          @click="hidePreviewBar = !hidePreviewBar"
        >
          Preview
        </div>
        <div class="relative flex items-center w-full h-full overflow-x-auto">
          <div class="absolute flex items-center" v-if="!hidePreviewBar">
            <div
              :class="[previewClass, 'flex items-center p-1', { 'bg-blue-200': showPreview }]"
              @click="showPreview = !showPreview"
            >
              <i class="fa fa-cog" aria-hidden="true"></i
              ><span class="text-xs" v-if="!showPreview"
                >&nbsp;{{ briefMachineActor.getSnapshot().value }}</span
              >
            </div>
            <div>
              <i class="fa fa-xmark mx-1" aria-hidden="true" @click="hidePreviewBar = true"></i>
            </div>
            <div class="flex text-xs transition opacity-0" :class="{ 'opacity-100': showPreview }">
              <select
                class="text-xs rounded-md p-1 border-gray-300 mr-1"
                id="previewStateSelect"
                @change="setPreviewState"
              >
                <option value="setNDAStatus">setNDAStatus</option>
                <option value="setAcceptedStatus">setAcceptedStatus</option>
                <option value="candidateReview">candidateReview</option>
                <option value="finishCandidateRejected">finishCandidateRejected</option>
                <option value="candidateUpload">candidateUpload</option>
                <option value="finishCandidateSubmissionAccepted">
                  finishCandidateSubmissionAccepted
                </option>
                <option value="candidateUploadReview">candidateUploadReview</option>
              </select>
              <div
                :class="[previewClass, { 'bg-green-200': isBrandView }]"
                @click="isBrandView = !isBrandView"
              >
                isBrandView
              </div>
              <div
                :class="[previewClass, { 'bg-green-200': isCreatorView }]"
                @click="isCreatorView = !isCreatorView"
              >
                isCreatorView
              </div>
              <div v-if="isBrandView" class="flex">
                <div
                  :class="[previewClass, { 'bg-green-200': isPublished }]"
                  @click="isPublished = !isPublished"
                >
                  isPublished
                </div>
              </div>
              <div v-if="isCreatorView" class="flex">
                <div
                  :class="[previewClass, { 'bg-green-200': isProfileFilled }]"
                  @click="isProfileFilled = !isProfileFilled"
                >
                  isProfileFilled
                </div>
                <div
                  :class="[previewClass, { 'bg-green-200': isNDAChecked }]"
                  @click="isNDAChecked = !isNDAChecked"
                >
                  isNDAChecked
                </div>
                <div
                  :class="[previewClass, { 'bg-green-200': isNDASubmitted }]"
                  @click="isNDASubmitted = !isNDASubmitted"
                >
                  isNDASubmitted
                </div>
                <div
                  :class="[previewClass, { 'bg-green-200': isNDAAccepted }]"
                  @click="isNDAAccepted = !isNDAAccepted"
                >
                  isNDAAccepted
                </div>
                <div
                  :class="[previewClass, { 'bg-green-200': isAccepted }]"
                  @click="isAccepted = !isAccepted"
                >
                  isAccepted
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentTab === 'candidates' && isBrandView && brief">
        <BriefDetailsCandidates
          :brief="brief"
          :on-candidate-profile-status-event="candidateProfileStatus"
          :isPreviewMode="showPreview"
          :isBriefLoading="isLoading"
          :isClosedBrief="isClosedBrief"
        />
      </div>

      <div v-if="currentTab === 'qa' && brief">
        <BriefDetailsQA
          :brief="brief"
          :brief_id="brief.id"
          :reference_id="brief.id"
          :profile_user_id="accountId"
          :reference_type="'brief'"
        />
      </div>

      <div v-if="currentTab === 'messages' && isCreatorView && brief">
        <BriefDetailsQA
          :brief="brief"
          :brief_id="brief.id"
          :reference_id="loggedInUserCandidateProfile?.id"
          :profile_user_id="accountId"
          :dm_candidate_id="accountId"
          :reference_type="'profile'"
          :called_from="'main_page'"
          :isBriefLoading="isLoading"
        />
      </div>

      <div v-if="currentTab === 'submissions' && brief">
        <BriefDetailsSubmissions
          v-if="isBrandView"
          :brief="brief"
          :on-candidate-profile-status-event="candidateProfileStatus"
          :isBriefLoading="isLoading"
          :isClosedBrief="isClosedBrief"
        />
        <BriefDetailsMySubmissions
          v-if="isCreatorView"
          :brief="brief"
          :loggedInUserCandidateHasFeedback="loggedInUserCandidateHasFeedback"
          :on-submit-content-event="submitContent"
          :uppyBusy="uppyBusy"
          :uppyProgress="uppyProgress"
          :isBriefLoading="isLoading"
          :isClosedBrief="isClosedBrief"
        />
      </div>

      <div v-if="currentTab === 'licencedcontent' && brief">
        <BriefDetailsLicencedContent v-if="isBrandView" :brief="brief" />
        <BriefDetailsMyLicencedContent
          v-if="isCreatorView"
          :brief="brief"
          :reloadBrief="refreshBrief"
        />
      </div>

      <div v-if="currentTab == 'details'">
        <div v-if="isCreatorView && !isClosedBrief">
          <!-- setAcceptedStatus -->
          <div
            v-if="
              ((!previewState && briefMachineActor.getSnapshot().matches('setAcceptedStatus')) ||
                previewState == 'setAcceptedStatus') &&
              (isNDASubmitted || brief?.type === 'public')
            "
            class="bg-white w-full rounded-md px-8 pt-6 pb-8 mb-6 border-[#EAECF0]"
          >
            <div v-if="brief">
              <CreativesWizard :briefId="brief.id" @stepChanged="handleStepChanged" />
            </div>
            <div v-else>
              <BriefDetailsActionPanel border-color="#5386EA">
                <Alert type="error" text="You must be logged in to accept the NDA" />
              </BriefDetailsActionPanel>
            </div>
          </div>

          <!-- candidateReview -->
          <div
            v-if="
              (!previewState && briefMachineActor.getSnapshot().matches('candidateReview')) ||
              previewState == 'candidateReview'
            "
          >
            <div v-if="accountId">
              <BriefDetailsActionPanel
                title-icon="fa-unlock"
                title="Approval pending"
                border-color="#5386EA"
              >
                <div>
                  <p>
                    Thanks for showing your interest in the brief. The brand team are reviewing your
                    portfolio and experience. We will send you an email to let you know the next
                    steps as soon they have made a decision.
                  </p>
                  <br />
                  <p>Have a question? Why not head over to the Q&A tab to message the brand.</p>
                </div>
                <div class="flex my-3 pt-4">
                  <RouterLink
                    :to="{
                      name: 'briefs.briefdetails',
                      params: {
                        brief_id: brief?.slug || brief?.id,
                        active_tab: 'qa'
                      }
                    }"
                  >
                    <div class="action-button solid">Ask a question</div>
                  </RouterLink>
                </div>
              </BriefDetailsActionPanel>
            </div>
            <div v-else>
              <BriefDetailsActionPanel border-color="#5386EA">
                <Alert type="error" text="You must be logged in to accept the NDA" />
              </BriefDetailsActionPanel>
            </div>
          </div>

          <!-- finishCandidateRejected -->
          <div
            v-if="
              (!previewState &&
                briefMachineActor.getSnapshot().matches('finishCandidateRejected')) ||
              previewState == 'finishCandidateRejected'
            "
          >
            <div v-if="accountId">
              <BriefDetailsActionPanel
                title-icon="fa-unlock"
                title="Snap! Not this time I'm afraid."
                border-color="#5386EA"
              >
                <div>
                  <p>
                    The brand has selected another creator for this brief. Better luck on the next
                    one.
                  </p>
                  <br />
                  <p>
                    Why not check out the more live brand briefs? You can also upload existing
                    content to our stock library, where other brands can search for, and license it.
                  </p>
                </div>
              </BriefDetailsActionPanel>
            </div>
            <div v-else>
              <BriefDetailsActionPanel border-color="#5386EA">
                <Alert type="error" text="You must be logged in to accept the NDA" />
              </BriefDetailsActionPanel>
            </div>
          </div>
        </div>

        <div v-if="isBrandView && !isClosedBrief">
          <BriefDetailsActionPanel title-icon="fa-unlock" title="Your Brief" border-color="#5386EA">
            <div>
              <div v-if="isPublished">
                <div v-if="haveApprovedCandidates">
                  Now that you have Accepted Candidates to this Brief, you can no longer Edit your
                  brief details. Continue to progress and message with your Candidates from the
                  Candidates and Submissions tabs. Good luck collaborating!
                </div>
                <div v-else>
                  Your brief will now be visible to creators who visit the View Briefs page. You
                  might want to increase the visibility of your brief by sharing a direct link to
                  the brief with creators you would like to work with.
                </div>
              </div>
              <div v-else>
                Your brief will now be saved to Drafts in your My Briefs page. You can continue to
                Edit the brief to increase the interest in your brief from candidate creators until
                you Publish.
              </div>
            </div>
            <div class="flex my-3 pt-6">
              <div v-if="!isPublished" @click="onPublish" class="action-button solid">Publish</div>
              <div v-if="!isPublished" class="mx-4"></div>
              <router-link
                v-if="
                  brief?.id &&
                  !haveApprovedCandidates &&
                  (teamStore.can('team.briefs.update') || brief?.owner === accountId)
                "
                class="action-button hollow"
                :to="{
                  name: 'marketplace.edit-brief',
                  params: {
                    brief_id: brief.id
                  }
                }"
              >
                Edit Brief
              </router-link>
              <div
                v-else
                class="border rounded-full px-6 py-2 text-sm font-bold hover:opacity-90 border-[#7C8185] bg-white text-[#7C8185] cursor-not-allowed"
              >
                Edit Brief
              </div>
            </div>
          </BriefDetailsActionPanel>
        </div>

        <!-- Brief details -->
        <div v-if="currentStep <= 1" class="w-full md:my-6 md:p-6 bg-white rounded-md">
          <div class="md:py-12 md:px-12 rounded-md border-[#EAECF0]">
            <div class="flex flex-col">
              <div v-if="!isMobile" class="w-full my-3">
                <span class="font-bold text-md">{{ getTitle() }}</span>
              </div>
              <div class="w-full flex flex-wrap mt-3" v-if="brief">
                <div class="w-full md:w-1/2">
                  <div class="my-3">
                    <span class="block font-semibold mb-3">Summary:</span>
                    <span v-if="isAccepted" class="text-sm">
                      <Markdown v-if="brief.summary" :content="convertNewLine(brief.summary)" />
                    </span>
                    <div v-if="!isAccepted" class="text-sm lg:text-md">
                      <Markdown
                        v-if="brief.ndaSummary"
                        :content="convertNewLine(brief.ndaSummary)"
                      />
                    </div>
                  </div>
                  <div class="my-3 flex flex-col">
                    <div>
                      <strong class="font-semibold">Accepted Formats:</strong>
                    </div>
                    <div class="text-sm mt-3">
                      {{ formatTypes(brief.contentTypes) }}
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/2">
                  <div class="md:ml-3">
                    <span class="block my-1 pb-3">
                      <strong class="font-semibold pb-1">Key dates:</strong>
                    </span>
                    <div class="flex flex-col md:flex-row my-1">
                      <div>
                        <strong class="font-semibold pb-1 md:p-0"
                          >Application of interest by:&nbsp;</strong
                        >
                      </div>
                      <div class="text-sm my-3 md:m-0.5">
                        {{ convertDBDate(brief.interestDeadline) }}
                      </div>
                    </div>
                    <div class="flex flex-col md:flex-row my-1">
                      <div>
                        <strong class="font-semibold pb-1"
                          >Final project submission & delivery due:&nbsp;
                        </strong>
                      </div>
                      <div class="text-sm my-3 md:m-0.5">
                        {{ convertDBDate(brief.submissionDeadline) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isAccepted" class="w-full mt-3">
                <div class="mt-3 text-sm">
                  <MediaCarousel
                    :items="metaData"
                    :showCount="true"
                    showCountTitle="Example Media"
                    dataType="objects"
                    noContentMsg="No examples yet."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- setNDAStatus -->
        <div v-if="isCreatorView && !isClosedBrief">
          <div
            v-if="
              (!previewState && briefMachineActor.getSnapshot().matches('setNDAStatus')) ||
              previewState == 'setNDAStatus'
            "
          >
            <div v-if="brief">
              <Wrapper>
                <div class="mt-14 border-2 border-[#5386EA] rounded-lg py-3 px-6 flex items-center">
                  <div class="flex">
                    <div>
                      <div>
                        <h2 class="p-0 m-0 mt-3 text-left">NDA approval required</h2>
                        <div class="my-6">
                          <p class="text-left text-md font-medium text-gray-600">
                            Please click the proceed button below to accept
                            <a
                              class="underline hover:opacity-80"
                              target="_blank"
                              rel="noopener"
                              :href="extractFileUrl(brief?.ndaText)"
                              >the NDA</a
                            >. We will then approve you to see all the information so you can decide
                            if you would like to respond to the brief.
                          </p>
                        </div>
                      </div>
                      <div class="mb-3">
                        <RouterLink
                          :to="{
                            name: 'briefs.list'
                          }"
                        >
                          <button
                            type="button"
                            class="py-1.5 px-6 min-w-28 border-2 border-[#FF008B] text-[#FF008B] rounded-full hover:bg-[#FF008B] hover:text-white"
                          >
                            <span>Decline</span>
                          </button>
                        </RouterLink>
                        &nbsp;
                        <button
                          type="button"
                          class="py-1.5 px-6 min-w-28 border-2 border-[#FF008B] text-white bg-[#FF008B] rounded-full hover:opacity-85"
                          @click="submitNDA()"
                        >
                          <span>Proceed</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- NDA text -->
                  <div class="flex-none ml-3 h-full">
                    <a target="_blank" rel="noopener" :href="extractFileUrl(brief?.ndaText)">
                      <MediaItem :item="{ file_url: extractFileUrl(brief?.ndaText) }" />
                    </a>
                  </div>
                </div>
              </Wrapper>
            </div>
            <div v-else>
              <BriefDetailsActionPanel border-color="#5386EA">
                <Alert type="error" text="You must be logged in to accept the NDA" />
              </BriefDetailsActionPanel>
            </div>
          </div>
        </div>

        <!-- Brief sections -->
        <div v-if="(isAccepted || isBrandView) && currentStep <= 1">
          <div v-for="(section, index) in combinedSections" :key="index">
            <div class="w-full my-6">
              <BriefDetailsPageSplitWithImage
                v-if="section.type == 'Image on the left and text on the right'"
                :bg-gradient="false"
                :image-classes="['max-h-[475px]', '-mb-12', '-ml-20']"
                :image-source="section.image"
                :image-position="`left`"
                :class="
                  index % 2 === 0
                    ? 'py-6 px-6 md:py-12 md:px-12 bg-gradient-to-r from-[#2F2FA5] to-[#FA00FF] rounded-md text-white'
                    : 'py-6 px-6 md:py-12 md:px-12 bg-white rounded-md border-[#EAECF0]'
                "
                :title="section.title"
                :body="convertNewLine(section.content)"
              />
              <BriefDetailsPageSplitWithCarousel
                v-else-if="section.type == 'Text on left and carousel on right'"
                :bg-gradient="false"
                :image-classes="['max-h-[475px]', '-mb-12', '-ml-20']"
                :image-source="section.image"
                :image-position="`left`"
                :class="
                  index % 2 === 0
                    ? 'py-6 px-6 md:py-12 md:px-12 bg-white rounded-md border-[#EAECF0]'
                    : 'py-6 px-6 md:py-12 md:px-12 bg-white rounded-md border-[#EAECF0]'
                "
                :title="section.title"
                :body="convertNewLine(section.content)"
                :imageItems="Array.isArray(section.imageItems) ? section.imageItems : []"
                :fileItems="Array.isArray(section.fileItems) ? section.fileItems : []"
                :brief="brief"
              />
              <BriefDetailsPageSplitWithImage
                v-else
                :bg-gradient="true"
                :image-classes="['max-h-[475px]', 'float-right', '-mb-12', '-mr-210']"
                :image-source="section.image"
                :image-position="`right`"
                :class="
                  index % 2 === 0
                    ? 'py-6 px-6 md:py-12 md:px-12 bg-gradient-to-r from-[#2F2FA5] to-[#FA00FF] rounded-md text-white'
                    : 'py-6 px-6 md:py-12 md:px-12 bg-white rounded-md border-[#EAECF0]'
                "
                :title="section.title"
                :body="convertNewLine(section.content)"
              />
            </div>
          </div>
          <div v-for="(section, index) in brief?.sections" :key="index">
            <div class="w-full my-6">
              <BriefDetailsPageFullWidth
                v-if="section.type == 'Only text'"
                :class="
                  index % 2 === 0
                    ? 'py-6 px-6 md:py-12 md:px-12 bg-gradient-to-r from-[#2F2FA5] to-[#FA00FF] rounded-md text-white'
                    : 'py-6 px-6 md:py-12 md:px-12 rounded-md border border-[#EAECF0]'
                "
                :title="section.title"
                :body="convertNewLine(section.content)"
              />
              <BriefDetailsPageSplitWithImage
                v-else-if="section.type == 'Image on the left and text on the right'"
                :bg-gradient="false"
                :image-classes="['max-h-[375px]', '-mb-12', '-ml-20']"
                :image-source="section.image"
                :image-position="`left`"
                :class="
                  index % 2 === 0
                    ? 'py-6 px-6 md:py-12 md:px-12 bg-gradient-to-r from-[#2F2FA5] to-[#FA00FF] rounded-md text-white'
                    : 'py-6 px-6 md:py-12 md:px-12 rounded-md border border-[#EAECF0]'
                "
                :title="section.title"
                :body="convertNewLine(section.content)"
              />
              <BriefDetailsPageSplitWithImage
                v-else
                :bg-gradient="true"
                :image-classes="['max-h-[375px]', 'float-right', '-mb-12', '-mr-210']"
                :image-source="section.image"
                :image-position="`right`"
                :class="
                  index % 2 === 0
                    ? 'py-6 px-6 md:py-12 md:px-12 bg-gradient-to-r from-[#2F2FA5] to-[#FA00FF] rounded-md text-white'
                    : 'py-6 px-6 md:py-12 md:px-12 rounded-md border border-[#EAECF0]'
                "
                :title="section.title"
                :body="convertNewLine(section.content)"
              />
            </div>
          </div>
        </div>

        <!-- Ready to start -->
        <div v-if="isCreatorView && !isClosedBrief">
          <div
            v-if="
              ((!previewState && briefMachineActor.getSnapshot().matches('setAcceptedStatus')) ||
                previewState == 'setAcceptedStatus') &&
              (isNDASubmitted || brief?.type === 'public')
            "
          >
            <div v-if="brief && currentStep <= 1">
              <CreativesWizardStart
                :showStep="'1'"
                :onDone="
                  () => {
                    changeStep(2)
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </BriefsLayout>
</template>

<style scoped lang="scss">
.mobile .tab-link {
  @apply pl-0 pr-5;
}

.tab-link.selected {
  color: #ff008b;
}

@-webkit-keyframes animate-stripes {
  100% {
    background-position: -100px 0px;
  }
}

@keyframes animate-stripes {
  100% {
    background-position: -100px 0px;
  }
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  height: 16px;
}

progress[value]::-webkit-progress-value {
  background-image:
    -webkit-linear-gradient(
      -45deg,
      transparent 33%,
      rgba(0, 0, 0, 0.1) 33%,
      rgba(0, 0, 0, 0.1) 66%,
      transparent 66%
    ),
    -webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25)),
    -webkit-linear-gradient(left, #09c, #f44);

  border-radius: 2px;
  background-size:
    35px 20px,
    100% 100%,
    100% 100%;

  -webkit-animation: animate-stripes 5s linear infinite;
  animation: animate-stripes 5s linear infinite;
}

.non-clickable {
  cursor: default;
  color: grey;
}

.flex.overflow-x-auto {
  -webkit-overflow-scrolling: touch;
  /* Enables smooth scrolling on iOS devices */
}

.content-image {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-right: 10px;
}

#mobile-menu {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
}
</style>
