<script lang="ts" setup>
import { ref, watchEffect } from 'vue'
import { getTeams } from '@/queries/api'
import BriefsLayout from '@/Layouts/BriefsLayout.vue'
import Auth from '@/components/Pages/Creatives/Wizard/Shared/Auth.vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import ActionButton from '@/components/Pages/Teams/Partial/ActionButton.vue'

const nearWalletStore = useNearWalletStore()

const teams = ref<Team[]>([])

watchEffect(async () => {
  if (nearWalletStore.accountId) {
    teams.value = await getTeams()
  }
})
</script>

<template>
  <BriefsLayout title="Teams" selectedLinkId="">
    <Auth>
      <div>
        <div>
          <div
            class="-mb-4 uppercase tracking-widest font-bold text-xs text-gray-500 hover:text-[#FF008B]"
          >
            <span>&nbsp;</span>
          </div>
          <div class="flex items-center">
            <h1 class="font-bold tracking-wider text-3xl">Teams</h1>
            <router-link
              :to="{
                name: 'teams.create'
              }"
            >
              <ActionButton icon="fa-solid fa-plus-circle"> Create Team </ActionButton>
            </router-link>
          </div>
        </div>
        <div class="flex flex-wrap items-center -ml-3">
          <router-link
            v-for="team in teams"
            :key="team.uuid"
            :to="{
              name: 'teams.show',
              params: {
                nickname: team.nickname
              }
            }"
            class="group w-full sm:w-1/3 md:w-1/4 xl:w-1/5"
          >
            <div class="m-3 py-1.5 px-6 rounded-md border hover:border-[#FF008B]">
              <h2 class="text-left tracking-wider font-medium group-hover:text-[#FF008B]">
                {{ team.name }}
              </h2>
            </div>
          </router-link>
        </div>
      </div>
    </Auth>
  </BriefsLayout>
</template>
