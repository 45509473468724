import TeamsIndex from '@/components/Pages/Teams/Index.vue'
import TeamsCreate from '@/components/Pages/Teams/Form.vue'
import TeamShow from '@/components/Pages/Teams/Show.vue'

export default [
  {
    path: '/teams',
    children: [
      {
        path: '',
        component: TeamsIndex,
        name: 'teams.index',
        props: true
      },
      {
        path: 'create',
        component: TeamsCreate,
        name: 'teams.create',
        props: true
      },
      {
        path: ':nickname/:view?',
        component: TeamShow,
        name: 'teams.show',
        props: true
      }
    ]
  }
]
