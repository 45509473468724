<script lang="ts" setup>
// @ts-nocheck
import { ref, watchEffect } from 'vue'
import { useNearWalletStore } from '@/stores/near-wallet'
import { storeToRefs } from 'pinia'
import { fetchProfile } from '@/queries/api'
import { useUcanStore } from '@/stores/ucan'

import BriefDetailsDownloadAllButton from '@briefs/BriefDetailsDownloadAllButton.vue'
import BriefDetailsPayment from '@briefs/BriefDetailsPayment.vue'
import MediaItemTileSubmission from '@media/MediaItemTileSubmission.vue'
import SurveyRating from '@/components/SurveyRating.vue'

const ucanStore = useUcanStore()
const nearWalletStore = useNearWalletStore()
const { accountId, balance } = storeToRefs(nearWalletStore)

const props = withDefaults(
  defineProps<{
    brief: SmartBrief
    reloadBrief: Function
    showModal: boolean
  }>(),
  {
    reloadBrief: () => {},
    showModal: true
  }
)

const filtered = ref<SmartBriefContent[]>([])
const showModal = ref(props.showModal)

watchEffect(async () => {
  try {
    if (props.brief?.acceptedProfiles && props.brief.candidateProfiles) {
      const acceptedUserIds = props.brief.acceptedProfiles
      const acceptedProfiles = props.brief.candidateProfiles.filter(
        (profile) => profile.userId === accountId.value && acceptedUserIds.includes(profile.userId)
      )
      const profileData = await fetchProfile(props.brief.owner)
      const ownerName = `${profileData?.firstName} ${profileData?.lastName}`
      if (acceptedProfiles && acceptedProfiles[0]) {
        const updatedContents = acceptedProfiles.map((profile) => {
          return {
            ...profile,
            contents: profile.contents.map((content) => ({
              ...content,
              amountPaid: profile.amountPaid,
              contentAcceptedAt: profile.contentAcceptedAt,
              creatorName: ownerName
            }))
          }
        })
        if (updatedContents && updatedContents[0]) {
          filtered.value = updatedContents[0].contents
        }
      }
    }
  } catch (err) {
    //
  }
})

const hideModal = () => {
  showModal.value = false
}
const isActive = ref()
const setActive = (id) => {
  console.log('setActive', id)
  isActive.value = id
}
console.log(filtered)
</script>

<template>
  <div class="flex flex-col min-h-screen">
    <div
      v-if="showModal && filtered.length"
      class="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50 overflow-hidden"
    >
      <div class="w-full max-w-sm md:max-w-md lg:max-w-lg mx-auto p-4 md:p-6 lg:p-8">
        <BriefDetailsPayment
          v-if="props.brief"
          :amount="balance.USDC"
          :paid="true"
          :brief="props.brief"
          :reloadBrief="reloadBrief"
          :showModal="showModal"
          @close="hideModal"
        />
      </div>
    </div>
    <div class="bg-white w-full rounded-md md:px-8 md:py-8 mb-6">
      <div class="w-full">
        <h3 class="font-semibold text-xl tracking-wide" style="text-align: left">
          My licensed content
        </h3>
      </div>

      <div class="py-6">
        <hr />
      </div>
      <div v-if="filtered.length" class="bg-white w-full rounded-md px-8 pb-8 mt-9 mb-6">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div
            v-for="(entry, index) in filtered"
            :key="index"
            class="mr-2 md:mr-4"
            :style="{ height: '100%' }"
          >
            <MediaItemTileSubmission
              @videoPlaying="setActive"
              :item="entry"
              :links="true"
              :isActive="isActive"
              :notEncrypted="false"
              :showOwner="true"
              :showDescription="true"
              :isAccepted="false"
              :brief="props.brief"
              :imageCss="['md:max-h-[250px]']"
            />
          </div>
        </div>
      </div>
      <div class="w-full mt-6 mb-3 mx-4" v-if="accountId && filtered.length">
        <BriefDetailsDownloadAllButton
          :items="filtered"
          :accountId="accountId"
          :isAccepted="true"
          :brief="props.brief"
        />
      </div>
      <div v-if="filtered.length" class="mt-12">
        <SurveyRating
          title="Rate your experience:"
          class="max-w-[360px] mx-auto"
          :id="{
            production: '018ff23d-8a77-0000-16a5-947dc60cd6f8',
            uat: '018ff23e-10e9-0000-5987-6cd0ff167056',
            preview: '018ff23e-393d-0000-709c-2c0ab5d6caa0'
          }"
        />
      </div>
    </div>
  </div>
</template>
